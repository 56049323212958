import styled from 'styled-components'

export const TextEditor = styled.div`
  .wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
  .editor-class {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .toolbar-class {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
`

export const Button = styled.button`
  width: 180px;
  max-width: 90%;
  border-radius: 8px;
  color: #fff;
  background-color: var(--primary);
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  padding: 0.6rem 0.8rem;
  transition: all 0.3s ease;

  &:hover {
    transition: all 0.3s ease;
    opacity: 0.8;
  }
`

export const Preview = styled.div`
  margin-top: 30px;
  width: 100%;
`
