import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useAuth } from '../../contexts/AuthContext'

import * as S from './style'

export interface DropzoneProps {
  image?: string
  message?: string
  typeName?: string
  mimeTypes?: {}
  handleOndrop?: (files: File[]) => void
  init?: any
  acceptedFile?: File | null
  children?: React.ReactNode
  imageDrop?: any
  progress?: number
}

export default function Dropzone ({
  image,
  message,
  typeName,
  mimeTypes,
  handleOndrop,
  init,
  acceptedFile,
  children,
  imageDrop
}: DropzoneProps): JSX.Element {
  const onDragLeave = useCallback(() => {}, [])
  const onDragEnter = useCallback(
    (isAccepted: boolean, isReject: boolean) => {},
    []
  )
  const onDropRejected = useCallback((files: any) => {}, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: handleOndrop,
    onDropRejected,
    onDragLeave,
    onDragEnter: () => onDragEnter(isDragAccept, isDragReject),
    maxFiles: 1,
    accept: mimeTypes
  })

  const { token } = useAuth()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    init
  }, [token])

  return (
    <>
      <S.DropZone
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
          maxFiles: 1
        })}
      >
        <input role='button' {...getInputProps()} />
        <h3>{typeName}</h3>

        {isDragActive
          ? (
          <p>Solte aqui...</p>
            )
          : (
          <p>Arraste uma imagem para cá ou clique para selecionar</p>
            )}
        { image !== '' &&
          <img
            src={image}
            width={'100%'}
            height={'100%'}
            style={{ maxHeight: '300px', objectFit: 'fill' }}
          />
        }
        {imageDrop}
        {message !== null || message !== undefined || message !== ''
          ? (
          <S.DropZoneMessage>{message}</S.DropZoneMessage>
            )
          : (
              ''
            )}

        {acceptedFile != null && (
          <p style={{ color: 'black', fontWeight: '700' }}>
            {acceptedFile.name}
          </p>
        )}
      </S.DropZone>
      {children}
    </>
  )
}
