import { useCallback, useEffect, useMemo, useState } from 'react';
import DefaultAvatar from '../../assets/img/avatar.png';
import { useAuth } from '../../contexts/AuthContext';
import { useMenu } from '../../contexts/DesktopMenuContext';
import api from '../../services/api';
import MenuButton from './MenuButton';
import * as S from './style';

export default function SidebarComponent () {
  const [catalogUrl, setCatalogUrl] = useState();
  const { userCan } = useAuth();

  const { open, menuItens } = useMenu();

  const { avatar, user, code } = useAuth();
  const Avatar = avatar || DefaultAvatar;

  const name = useMemo(() => user ? user.name : '', [user]);

  const loadCatalog = useCallback(async () => {
    const response = await api.get('/premmiar');
    setCatalogUrl(response.data.catalogUrl);
  }, []);

  useEffect(() => {
    loadCatalog();
  }, [loadCatalog]);

  if (!user) return <></>;

  return (
    <S.SidebarContainer>
      <nav id="navigation-widget-small" className={`navigation-widget navigation-widget-desktop closed sidebar left ${open ? 'delayed' : 'hidden'}`}>
        <ul className="menu small">
          {menuItens && menuItens.map((m, index) => <MenuButton key={index} title={false} {...m} />)}
        </ul>
      </nav>

      <nav id="navigation-widget" className={`navigation-widget navigation-widget-desktop sidebar left ${open ? 'hidden' : 'delayed'}`} data-simplebar>

        <ul className="menu mt-4">
          {menuItens && menuItens.map((m, index) => <MenuButton key={index} {...m} />)}
          {userCan('access:premmiar-2022') && catalogUrl && <li className={'menu-item'}>
            <a className="menu-item-link" href={catalogUrl} target='_blank' rel="noreferrer">
              <svg className="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
              </svg>
              Catalogo de premios
            </a>
          </li>}
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
        </ul>
      </nav>

    </S.SidebarContainer>
  );
}
