import React from 'react'
import { Line } from 'rc-progress'

interface propsProgress {
  color?: string
  progress?: number
  width?: number
}
export const Progress = ({ color, progress, width }: propsProgress): any => {
  return (
    <>
      <Line percent={progress} strokeWidth={width} strokeColor={color} />
    </>
  )
}
