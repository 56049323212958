import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client'
import Dropzone from '../../components/Dropzone'
import * as Style from '../../components/Dropzone/style'
import { Progress } from '../../components/Progress'
import Template from '../../template'
import * as S from './style'
function AdminUpload ({ type }) {
  // Endpoints enabled at backend
  const [acceptedFile, setAcceptedFile] = useState()
  const [errors, setErrors] = useState([])
  const [buttonText, setButtonText] = useState('Enviar planilha')
  const [disableButton, setDisableButton] = useState(false)
  const [, setSocket] = useState()
  const [period, setPeriod] = useState()
  const [step, setStep] = useState()
  const [progressValue, setProgressValue] = useState(0)
  const enableType = {
    results: {
      text: 'Classificação',
      url: 'results/import'
    }
  }
  const APIENDPOINT = enableType[type].url
  const send = useCallback(async () => {
    const formData = new FormData()
    formData.append('resultados', acceptedFile)
    formData.append('period', period)
    formData.append('type', type)
    formData.append('step', step ?? 1)
    setButtonText('Enviando')
    setErrors([])
    setDisableButton(true)
    try {
      const token = localStorage.getItem('token')
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}${APIENDPOINT}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          },
          onUploadProgress: (e) => {
            // let progress = Math.round((e.loaded * 100) / e.total);
            // setProgress(progress);
          }
        }
      )
      setAcceptedFile()
      toast.success('A planilha foi enviada com sucesso!', { theme: 'colored' })
    } catch (err) {
      console.log(err)
      toast.error('Ocorreu um erro ao tentar enviar a planilha', {
        theme: 'colored'
      })
    }
    setButtonText('Enviar planilha')
    setDisableButton(false)
  }, [acceptedFile, APIENDPOINT, period, type, step])
  const onDrop = useCallback((newAcceptedFiles) => {
    const [file] = newAcceptedFiles
    setAcceptedFile(file)
    handleProgress()
  }, [])
  const handleProgress = async () => {
    setProgressValue(0)
    const totalSteps = 10
    const delay = 100

    for (let i = 1; i <= totalSteps; i++) {
      await new Promise((resolve) => setTimeout(resolve, delay))
      const newProgress = Math.round((i / totalSteps) * 100)
      setProgressValue(newProgress)
    }
  }
  const renderButtonGroup = () => {
    return (
      <>
        <Style.ButtonGroup>
          <button
            className="button primary"
            disabled={disableButton}
            onClick={send}
          >
            {buttonText}
          </button>
        </Style.ButtonGroup>

        {errors && (
          <Style.Table>
            <thead>
              <tr>
                <th>Linha</th>
                <th>Erros</th>
              </tr>
            </thead>
            {errors.map((e, index) => (
              <thead key={index}>
                <tr>
                  <td>{e.number}</td>
                  <td>
                    {e.errors.map((err) => (
                      <p>{err}</p>
                    ))}
                  </td>
                </tr>
              </thead>
            ))}
          </Style.Table>
        )}
      </>
    )
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    const ioServer = io.connect(process.env.REACT_APP_BASE_URL, {
      query: { token },
      transports: ['websocket']
    })

    ioServer.on('import-progress', (chunk) => {
      console.log(chunk)
      if (chunk.errors.length > 0) {
        setErrors((errors) => {
          return [chunk, ...errors]
        })
      }
    })

    ioServer.on('import-completed', (chunk) => {
      toast.success('Planilha importada com sucesso!', { theme: 'colored' })
    })

    setSocket(ioServer)
    return () => ioServer.close()
  }, [])

  return (
    <Template>
      <S.Grid className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Upload {enableType[type].text}</p>

              <h2 className="section-title">
                Envie uma planilha para atualizar
              </h2>
            </div>
          </div>

          <div className="grid-column">
            <div className="widget-box">
              <p className="widget-box-title">Upload {enableType[type].text}</p>
              <div className="widget-box-content">
                <Dropzone
                  init={() => initWS()}
                  acceptedFile={acceptedFile}
                  apiEndpoint={enableType[type].url}
                  mimeTypes={{
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                      ['.xlsx']
                  }}
                  handleOndrop={onDrop}
                  imageDrop={
                    <svg style={{ width: '40px', height: '40px' }}>
                      <use href="#svg-upload-xlsx" />
                    </svg>
                  }
                  message=" É permitido apenas o uso de planilhas no formato Excel (extensão .xlsx)"
                > {progressValue === 0 ? '' : <Progress color="#615dfa" progress={progressValue} width={1} />}
                  {renderButtonGroup()}
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
      </S.Grid>
    </Template>
  )
}

export default AdminUpload
