import { isAxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Icon from '../../assets/img/banner/overview-icon.png';
import BannerTitleComponent from '../../components/BannerTitle';
import InputSelect from '../../components/Forms/InputSelect';
import PageLoader from '../../components/PageLoader';
import { useAuth } from '../../contexts/AuthContext';
import { useWorksheetDate } from '../../hooks/useWorksheetDate';
import api from '../../services/api';
import Template from '../../template';
import RankingGeral from './RankingGeral';
import * as S from './style';

function Ranking () {
  const [ranking, setRanking] = useState();
  const [myPosition, setMyPosition] = useState();
  const [onda, setOnda] = useState();
  const [regionais, setRegionais] = useState([]);
  const [regionName, setRegionName] = useState();
  const [pontosExtras, setPontosExtras] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { holdingId } = useParams();

  const { user, doLogout, userCan } = useAuth();
  const period = useWorksheetDate();

  const loadPageData = useCallback(async () => {
    setIsLoading(true);
    try {
      const results = await api.get(`/ranking/${holdingId}`, { params: { stepId: onda || 1 } });
      setRanking(results.data.ranking);
      setMyPosition(results.data.myPosition);
      setRegionais(results.data.regions);
      setPontosExtras(results.data.pontosExtras);
      setRegionName(results.data.regionName);
    } catch (err) {
      console.log(err);
      if (isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          doLogout();
          return;
        }
      }
      toast.error('Ocorreu um erro inesperado, por favor tente novamente mais tarde!', { theme: 'colored' });
    }
    setIsLoading(false);
  }, [doLogout, holdingId, onda]);

  const updateOnda = useCallback(async (onda) => {
    setOnda(onda);
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  if (!ranking) return <PageLoader />;
  return <Template>

    <BannerTitleComponent
      imgUrl={Icon}
      title="Resultados"
      subTitle=""
      imgAlt="accounthub-icon"
    />

    <div className="section-header">
      <div className="section-header-info">
        <h2 className="section-title">Resultados</h2>
        <p>Resultados parciais etapa 1 - Atualizado em {period}</p>
      </div>
    </div>

    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">
            <>
              <S.FiltrosCargo>
                <InputSelect label="Selecione uma etapa" onChange={(evt) => updateOnda(evt)} large={true}>
                  <option value="1">Etapa 1</option>
                  <option value="2">Etapa 2</option>
                </InputSelect>
                {userCan('ranking-all:read') && <InputSelect label="Selecione uma regional" onChange={(evt) => console.log(evt)} large={true}>
                  {regionais && regionais.map((r, index) => <option key={index} value={r.id}>{r.name}</option>)}
                </InputSelect>}
              </S.FiltrosCargo>
              {!isLoading && <RankingGeral regionName={regionName} pontosExtras={pontosExtras} onda={onda} ranking={ranking} myPosition={myPosition} canal={user.canal} />}
              {isLoading && <S.Loading>Carregando...</S.Loading>}
            </>
          </div>

        </div>
      </div>
    </div>
  </Template>;
}

export default Ranking;
