import styled from 'styled-components'

export const GridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 284px);
  justify-content: center;

  @media screen and (max-width: 460px) {
    grid-template-columns: 100%;
  }
`
