import styled from 'styled-components';

export const Container = styled.div`
  &>div {
    display: flex;
    align-items: center;
  }
`;

export const Checkbox = styled.input`
  margin-right: .5rem;
  aspect-ratio: 1;
  flex: 0 0 21px;
  @media (max-width: 480px) {
    flex: 0 0 24px;
  }
`;

export const Label = styled.label`
  user-select: none;

  a {
    color: #1DF161;
    text-decoration: underline;
  }
`;
