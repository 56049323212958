import styled from 'styled-components'
import searchIcon from '../../assets/img/search-icon.svg'

export const MultiSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgb(222, 222, 234);
  border-radius: 12px;
  margin-bottom: 0.75rem;
  position: relative;

  .label {
    padding: 0px 6px;
    background-color: var(--white);
    color: var(--text-light);
    font-size: 0.75rem;
    font-weight: 600;
    position: absolute;
    top: -8px;
    left: 12px;
    pointer-events: none;
  }
`

export const List = styled.div`
  margin: 12px 0px;
  display: flex;
  width: 50%;
  max-width: 50%;
  flex-direction: column;
  overflow-y: auto;
  height: 240px;
  border-right: 1px solid rgb(222, 222, 234);
`

export const ListResults = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`

export const Option = styled.div<{ $isSelected: boolean }>`
  width: 100%;
  padding: 4px 8px;
  font-size: 1rem;
  color: ${({ $isSelected }) => ($isSelected ? 'var(--primary)' : 'var(--dark)')};
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  text-align: left;
  border-radius: unset;
  cursor: pointer;
  font-weight: 600;
  user-select: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: rgb(235, 243, 239);
    color: var(--dark);
  }
`
export const SelectedOptions = styled.div`
  margin: 12px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  max-width: 50%;
  flex-direction: column;
  overflow-y: auto;
  height: 240px;
`

export const SelectedOption = styled(Option) <{ $isSelected?: boolean }>`
  color: var(--primary);
  position: relative;
  
  .remove-option {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    right: 8px;
    top: 0px;
    border-radius: 50%;
    color: red;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    height: 100%;

    &:hover {
      transition: all 0.3s ease-in-out;
      color: rgb(200, 20, 20)
    }
  }
`

export const SearchContainer = styled.div`
  margin: 0px 8px;
  position: relative;

  .search-icon {
    position: absolute;
    background-image: url(${searchIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    right: 14px;
    top: 14px;
    width: 20px;
    height: 20px;
  }
`
