import { Link } from 'react-router-dom';
import styled from 'styled-components';
import background from '../../assets/img/banner/bannerhome.png';

export const HomeContainer = styled.div`
&&& div.grid {
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-top: 140px;
  }
  div.grid.medium-space {
    margin-top: 32px;
    grid-column: span 2;
  }
}
`;

export const SectionBannerContainer = styled.div`
position: relative;
padding-bottom: 26.09%;
`;

export const SectionBanner = styled.div`
position: absolute;
inset: 0;
border-radius: 12px;
background-image: url(${background});
background-size: contain;
background-repeat: no-repeat;
`;
export const SectionBannerImage = styled.img`
width: 100%;
height: auto;
display: block;
aspect-ratio: 1920 / 500;
border-radius: 12px;
`;

export const Cover = styled.div`
min-height: 120px;
text-align: center;
color: var(--white);
padding-top: 1.5rem;
background-repeat: no-repeat;
background-size: cover;

h4 {
  font-size: 1.25rem;
  color: inherit;
  text-shadow: 1px 1px 2px black;
}

small {
  font-size: 1rem;
  color: inherit;
  font-weight: bold;
  text-shadow: 1px 1px 2px black;
}
`;

export const BannerHome = styled.img``;

export const Grafico = styled.div``;

export const Grid = styled.div.attrs(() => {
  return {
    className: 'js-grid'
  };
})`
align-items: start;
display: grid;
grid-template-areas: "sidebar content";
grid-template-columns: 3fr 7fr;
grid-gap: 16px;
margin-left: 52px;
margin-right: 52px;


@media (max-width: 1280px) {
  grid-template-columns: 1fr;
  grid-template-areas: none;
  margin-left: 0;
  margin-right: 0;

}
iframe {
  width: 100%;
  margin-inline: auto;
  max-width: 1184px;
  aspect-ratio: 1.78;
  grid-column: span 2;
}
.hexagon-container {
  margin-inline: auto;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 124px;
  height: 124px;
  top: -60px;
  clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
  background-color: var(--white);
  .shape-outer {
    width: 85%;
    height: 85%;
  }
  span {
    width: 124px;
    height: 124px;
  }
}
.user-short-description {
  max-height: 50px;
  padding-top: 0;
  position: relative;
  .user-short-description-avatar {
    position: static;
    top: 0;
    left: 0;
    margin-left: unset;
    .hexagon-with-border {
    }
  }
}
`;

export const Stat = styled.div`
flex: 0;
padding: 0;
max-width: 150px;
margin: 0 auto;

small{
  text-align: center;
}

&:after {
  display: none;
}

p.user-stat-title {
  color: var(--text-light, var(--dark));
  font-size: 1.125rem;
  font-weight: bold;
  
  small {
    color: var(--gray);
    font-size: 1rem;
  }
}

&.vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.star{
  max-width: 50px;
  margin: 0 auto;
  padding-top: 15px;
}
`;

export const Solo = styled.div`
width: 100%;
height: auto;
text-align: center;
padding-bottom: 17px;

.results{
  padding-top: 9px;
  font-size: 15px;
  font-weight: bold;
}
`;

export const LastUpdateDate = styled.small`
margin-top: 8px;
font-size: .5rem;
font-weight: bold;
text-align: center;
letter-spacing: 1px;
`;

export const Porcentagem = styled.div`
font-size: 1.25rem;
font-weight: bold;
text-align: center;
line-height: 108.97px;
`;

export const UserStats = styled.div`
margin-top: 0 !important;
display: grid;
grid-template-columns: 1fr;
align-items: center;
grid-gap: 16px;
justify-content: center;
padding-top: 30px;

@media (max-width: 1280px) {
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 844px) {
  grid-template-columns: 1fr 1fr;
}

`;

export const Widget = styled.div`
overflow: hidden;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
min-height: 15rem;
padding: 0;
margin-top: 2rem;
position: relative;
.campaign-box-logo {
  position: absolute;
  object-position: right;
  width: min(40rem, 80%);
}
.campaign-box-bg {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
@media (max-width: 768px) {
  .campaign-box-logo {
    width: 90%;
  }
  .campaign-box-bg {
    width: unset;
    align-self: flex-start;
    object-fit: contain;
  }
  
}
`;

export const VimeoArea = styled.div`
position: relative;
inset: 0;
border: 1px solid #000;
width: 100%;
padding-bottom: 56.25%;
`;

export const VimeoEmbed = styled.iframe`
width: 100%;
height: 100%;
position: absolute;
inset: 0;
display: block;
`;

export const WidgetFooter = styled.div`
font-size: 1rem;
font-weight: bold;
padding: 1rem;
`;

export const GridArea = styled.div`
display: grid;
width: 100%;
grid-template-columns: 1fr 1fr;
grid-column-start: 1;
grid-column-end: 3;
column-gap: 8px;
row-gap: 24px;

@media (max-width: 1280px) {
  grid-column-start: 1;
  grid-column-end: 2;
  
  grid-template-columns: 1fr;
}
`;

export const GridFastAcccess = styled.div`
display: grid;
width: 100%;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-column-start: 1;
grid-column-end: 3;
column-gap: 32px;
row-gap: 24px;
max-width: 1180px;
@media (min-width: 1600px) {
  margin: auto;
}

@media (max-width: 1280px) {
  grid-column-start: 1;
  grid-column-end: 2;
  
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 630px) {
  grid-template-columns: 1fr;
}
`;

export const Banner = styled.div`
position: relative;
background: var(--primary);
background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
background-size: contain;
background-repeat: no-repeat;
background-position: center;
min-height: 140px;
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 32px;
padding-right: 32px;
width: 100%;
max-width: 592px;
margin: 0 auto;
border-radius: 20px;

@media (max-width: 375px) {
  min-height: auto;
  aspect-ratio: 295 / 73;
  width: 100%;
  padding: 0 16px;
}
`;

export const IconContainer = styled.div`
flex: 1 0 100px;
display: flex;
justify-content: center;

@media (max-width: 375px) {
  flex: 0 40px;
  margin-right: 8px;
}
`;

export const Icon = styled.img`
width: auto;
max-width: 100%;
height: auto;
display: block;

@media (max-width: 375px) {
  max-width: 40px;
  display: block;
}
`;

export const BannerTitle = styled.h2`
color: var(--white);
font-size: 1.0rem;

small {
  display: inline-block;
  font-size: .75rem;
  font-weight: bold;
  padding-top: 8px;
  
  span {
    font-weight: normal;
  }
}

@media (max-width: 375px) {
  font-size: 16px;
  
  small {
    font-size: 14px;
    display: none;
  }
}
`;

export const BannerButton = styled(Link)`
flex: 1 0 135px;
padding: 20px 32px;
margin-left: 16px;
background-color: var(--light);
font-weight: bold;
font-size: .75rem;
border-radius: 16px;
outline: none;
text-align: center;

&:after {
  content: "Ver todas";
}

@media (max-width: 905px) {
  position: absolute;
  inset: 0;
  margin: 0;
  overflow: hidden;
  background-color: transparent;
  text-indent: 9999999px;
  min-width: 100%;
  
  &:after {
    display: none;
  }
}

@media (max-width: 375px) {
  font-size: 14px;
  padding: 16px 8px;
  flex: initial;
  min-width: 80px;
  display: none;
}
`;

export const BannerLink = styled.a`
flex: 1 0 135px;
padding: 20px 32px;
margin-left: 16px;
background-color: var(--light);
font-weight: bold;
font-size: .75rem;
border-radius: 16px;
outline: none;
text-align: center;

&:after {
  content: "Ver todas";
}

@media (max-width: 905px) {
  position: absolute;
  inset: 0;
  margin: 0;
  overflow: hidden;
  background-color: transparent;
  text-indent: 9999999px;
  min-width: 100%;
  
  &:after {
    display: none;
  }
}

@media (max-width: 375px) {
  font-size: 14px;
  padding: 16px 8px;
  flex: initial;
  min-width: 80px;
  display: none;
}
`;

export const UserStatText = styled.p`
margin-top: 10px;
color: var(--text-light);
font-size: .6875rem;
font-weight: 700;
text-transform: uppercase;
text-align: center;
`;

export const ExpLine = styled.p`
color: var(--text-light);
font-size: 1.5rem;
font-weight: bold;
text-align: center;
`;

export const Rank = styled.div`
position: relative;
width: 100%;
height: 100%;
margin-top: -3.5rem;

img{
  margin-top: -1.5rem;
  width: 120px;
}

div {
  align-items: center;
  color: var(--light);
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: bold;
  inset: 0;
  justify-content: center;
  margin-top: -1.6rem;
  position: absolute;
  text-align: center;
  
  small {
    font-size: 60%;
    display: block;
    margin-bottom: -8px;
  }
}
`;

export const DesktopImage = styled.img`
@media (max-width: 960px) {
  display: none !important;
}
`;

export const MobileImage = styled.img`
@media (min-width: 960px) {
  display: none !important;
}
`;

export const UserPreviewInfo = styled.div`
&.user-preview-info {
  padding-bottom: 0;
}
`;

export const FastLink = styled(Link)`
position: relative;
display: flex;
flex-direction: column;
height: 100%;
padding: 26px 0 12px 28px;
&:hover {
  text-decoration: none;
}
button {
  background-color: #fff;
  text-align: left;
  margin-top: auto;
  padding: 4px 3px;
  width: 78px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    width: 100%;
    text-align: center;
  }
}
p {
  position: relative;
  z-index: 20;
  margin-bottom: 15px;
  &.product-category-box-text {
    margin-right: 5rem;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
&.results {
  background: linear-gradient(90deg, var(--primary), #20b851);
  button {
    span {
      font-size: 1rem;
      background: -webkit-linear-gradient(var(--primary), #20b851);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
&.mecanica {
  background: linear-gradient(90deg, var(--primary), #20b851);
  img {
    max-width: 120px;
  }
  button {
    span {
      font-size: 1rem;
      background: -webkit-linear-gradient(var(--primary), #20b851);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
&.quiz {
  background: linear-gradient(90deg, var(--primary), #20b851);
  button {
    span {
      font-size: 1rem;
      background: -webkit-linear-gradient(var(--primary), #20b851);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
&.contact {
  background: linear-gradient(90deg, var(--primary), #20b851);
  img {
    max-width: 105px;
    bottom: -24px;
  }
  button {
    span {
      font-size: 1rem;
      background: -webkit-linear-gradient(var(--primary), #20b851);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

`;

export const FastLinkImage = styled.img`
bottom: -20px;
max-width: 100px;
position: absolute;
right: -10px;
z-index: 10;
`;

export const FastLinkJarra = styled(FastLinkImage)`
max-width: 130px;
`;
export const FastLinkFruta = styled(FastLinkImage)`
max-width: 180px;
`;

export const Modal = styled.div`
p {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}
`;

export const HeroImgContainer = styled.div`
  margin: auto;
  grid-column: span 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  img {
    max-height: 310px;
    @media (min-width: 1440px) {
      max-height: 410px;
      flex-wrap: nowrap;

    }
  }
`;

export const GridPosts = styled.div.attrs(() => {
  return {
    className: 'grid grid-4-4-4 centered js-grid-posts'
  };
})`
  
  grid-column: span 2;
  margin: auto;
  max-width: 1180px;
  &.grid.grid-4-4-4 {
    margin-top: 1rem;
    width: 100%;
    grid-template-columns: repeat(3, 33%);
    @media (max-width: 960px) {
      grid-template-columns: 1fr;
    }
  }
  .post-preview-info-bottom {
    margin-top: 0.25rem;
  }
  figure.post-preview-image {
    background: #615dfa;
  }
`;
