import { ReactElement, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { ProfileContextType, User } from '../../..'
import Card from '../../../../../components/Card'
import PageLoaderComponent from '../../../../../components/PageLoader'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'
import AvatarBanner from './AvatarBanner'
import Badges from './Badges'
import InterestsInfo from './InterestsInfo'
import PersonalInfo from './PersonalInfo'
import ProfessionalInfo from './ProfessionalInfo'

import { useAuth } from '../../../../../contexts/AuthContext'
import * as S from './style'

function ProfileInfo (): ReactElement {
  const { user, setUser } = useOutletContext<ProfileContextType>()

  const [userData, setUserData] = useState<User>()

  const { userCan } = useAuth()

  useEffect(() => {
    if (user === null) return
    setUserData(user)
  }, [user])

  if ((userData === undefined || userData === null) || !('active' in userData)) {
    return <PageLoaderComponent />
  }

  return <>
    <SectionHeaderComponent pretitle="MEU PERFIL" title="Informações de Perfil" />

    <AvatarBanner />

    <Card className="single-card">
      <S.ProfileInfoTitle>Informações Pessoais</S.ProfileInfoTitle>

      <PersonalInfo user={userData} setUser={setUser} />
    </Card>

    {userCan('control:removed-item') === true && <Card className="single-card">
      <S.ProfileInfoTitle>Gerenciar Distintivos</S.ProfileInfoTitle>

      <Badges />
    </Card>}

    {userCan('control:removed-item') === true && <Card className="single-card">
      <S.ProfileInfoTitle>Interesses</S.ProfileInfoTitle>

      <InterestsInfo />
    </Card>}

    {userCan('control:removed-item') === true && <Card className="single-card">
      <S.ProfileInfoTitle>Educação e Trabalho</S.ProfileInfoTitle>

      <ProfessionalInfo />
    </Card>}
  </>
}

export default ProfileInfo
