import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageLoaderComponent from '../../components/PageLoader';
import api from '../../services/api';
import Template from '../../template';
import { currency } from '../../utils/format';
import * as S from './styled';

export default function Category () {
  const [filtered, setFiltered] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [priceFrom, setPriceFrom] = useState(null);
  const [name, setName] = useState(null);
  const [priceTo, setPriceTo] = useState(null);
  const [active, setActive] = useState([]);
  const [data, setData] = useState();
  const [order, setOrder] = useState(1)
  const { categoryId } = useParams()

  const handleActive = useCallback((e) => {
    if (e.target.checked) {
      const a = [...active, e.target.value];
      const filtered = data.produtos.filter(p => a.includes(String(p.categoria[0].id)));
      setActive(a);
      setFiltered(filtered);
      if (a.length === 0) {
        setFiltered(data.produtos);
      }
    } else {
      const a = [...active.filter(a => a !== e.target.value)];
      const filtered = data.produtos.filter(p => a.includes(String(p.categoria[0].id)));
      setActive(a);
      setFiltered(filtered);
      if (a.length === 0) {
        setFiltered(data.produtos);
      }
    }
  }, [data, active]);

  const handleFilter = useCallback((e) => {
    console.log(data)
    let filtered = data;

    if (priceFrom !== null) {
      filtered = filtered.filter(p => {
        return p.skus[0].price > parseFloat(priceFrom);
      });
    }

    if (priceTo !== null && priceTo !== '0') {
      filtered = filtered.filter(p => {
        return p.skus[0].price < parseFloat(priceTo);
      });
    }

    setFiltered(filtered);
  }, [data, priceFrom, priceTo]);

  const handleName = useCallback((e) => {
    e.preventDefault();
    if (name !== null) {
      const f = data.filter(p => {
        return p.name.indexOf(name) !== -1;
      });
      setFiltered(f);
    }
  }, [data, name]);

  const orderBy = useCallback((e) => {
    e.preventDefault();
    setFiltered((filtered) => {
      const a = filtered.sort((p1, p2) => {
        return p1.skus[0].price < p2.skus[0].price ? order : -order;
      });
      return [...a]
    });
  }, [order]);

  useEffect(() => {
    api.get('/products', { params: { categoryId } }).then(result => {
      setData(result.data.data)
    })
  }, [])

  useEffect(() => {
    if (!data) return;
    setFiltered(data);

    // const categorias = data.map(p => p.categoria[0]).reduce((acc, c) => {
    //   const a = acc.find(c2 => c2.nome === c.nome);
    //   if (!a) {
    //     acc.push(c);
    //   }
    //   return acc;
    // }, []);

    // setCategorias(categorias);
  }, [data]);

  if (!data) {
    return <PageLoaderComponent />;
  }

  return (
    <Template>
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Buscar produtos</p>

          <h2 className="section-title">Filtro de produtos</h2>
        </div>

        <div className="section-header-actions">
          <Link className="section-header-subsection" to='/marketplace'>Marketplace</Link>

          {/* <p className="section-header-subsection">Digital Items</p> */}
        </div>
      </div>

      <div className="section-filters-bar v4">
        <div className="section-filters-bar-actions">
          <form className="form">
            <div className="form-item split">
              <div className="form-input small">
                <label htmlFor="items-search"></label>
                <input type="text" id="items-search" name="items_search" value={name || ''} onChange={(e) => setName(e.target.value)} />
              </div>

              <button className="button primary" onClick={handleName}>
                <svg className="icon-magnifying-glass">
                  <use href="#svg-magnifying-glass"></use>
                </svg>
              </button>
            </div>
          </form>
        </div>

        <div className="section-filters-bar-actions">
          <form className="form">
            <div className="form-item split medium">
              <div className="form-select small">
                <label htmlFor="items-filter-order">Organizar por</label>
                <select id="items-filter-order" name="items_filter_order" onChange={(e) => setOrder(e.target.value)}>
                  <option value={-1}>Decrescente</option>
                  <option value={1}>Crescente</option>
                </select>
                <svg className="form-select-icon icon-small-arrow">
                  <use href="#svg-small-arrow"></use>
                </svg>
              </div>

              <button className="button secondary" onClick={orderBy}>Aplicar filtros</button>
            </div>
          </form>
        </div>
      </div>

      <div className="grid grid-3-9 small-space">
        <div className="marketplace-sidebar">
          <div className="sidebar-box">
            <p className="sidebar-box-title">Categorias</p>

            <div className="sidebar-box-items">
              {categorias.map(c => (<div className="checkbox-line">
                <div className="checkbox-wrap">
                  <input type="checkbox" id={c.id} name="category_all" value={c.id} onChange={handleActive} />
                  <div className="checkbox-box">
                    <svg className="icon-cross">
                      <use href="#svg-cross"></use>
                    </svg>
                  </div>
                  <label htmlFor={c.id}>{c.nome}</label>
                </div>

                <p className="checkbox-line-text">&nbsp;</p>
              </div>))}
            </div>

            <p className="sidebar-box-title">Range de preço</p>

            <div className="sidebar-box-items small-space">
              <div className="form-item split">
                <div className="form-input small active always-active currency-decorated">
                  <label htmlFor="price-from">De</label>
                  <input type="text" id="price-from" name="price_from" value={priceFrom | ''} onChange={(e) => setPriceFrom(e.target.value)} />
                </div>

                <div className="form-input small active always-active currency-decorated">
                  <label htmlFor="price-to">Até</label>
                  <input type="text" id="price-to" name="price_to" value={priceTo | ''} onChange={(e) => { setPriceTo(e.target.value); }} />
                </div>
              </div>
            </div>

            <p className="button small primary" onClick={handleFilter}>Aplicar filtro de preço!</p>
          </div>
        </div>

        <div className="marketplace-content">
          <div className="grid grid-3-3-3 centered">
            {filtered.map(p => <div className="product-preview" style={{ height: '100%' }}>
              <Link to={`/product/${p.id}`}>
                <S.Figure className="product-preview-image liquid">
                  <img src={p.skus[0].images[0].path} alt="item-01" />
                </S.Figure>
              </Link>

              <div className="product-preview-info">
                <p className="text-sticker"><span className="highlighted"></span>{currency(p.skus[0].price)}</p>

                <p className="product-preview-title"><Link to={`/product/${p.id}`}>{p.name}</Link></p>

              </div>

            </div>)}

          </div>

        </div>
      </div>
    </Template >
  );
}
