import { ReactElement, useCallback, useMemo } from 'react'
import bgImg1 from '../../assets/img/marketplace/category/01.png'
import bgImg2 from '../../assets/img/marketplace/category/02.png'
import bgImg3 from '../../assets/img/marketplace/category/03.png'
import bgImg4 from '../../assets/img/marketplace/category/04.png'
import CardMarketplace from '../../components/Card/CardMarketplace'
import PageLoaderComponent from '../../components/PageLoader'
import SectionHeaderComponent from '../../components/SectionHeaderComponent'
import { useFetch } from '../../hooks/useFetch'
import Template from '../../template'
import CategoryCard from './CategordyCard'
import * as S from './styled'

interface ProductData {
  id: number
  category: { name: string }
  cnpj: string
  code: number
  description: string
  name: string
  sellerId: number
  sellerName: string
  brandCode: number
  brandName: string
  fotoPequena: string
  fotoMedia: string
  fotoGrande: string
  palavraChave: string
  maisVendidos: number
  skus: Array<{ preco: number, images: any[] }>
  images: Array<{ path: string }>
}

export default function Marketplace (): ReactElement {
  const { data } = useFetch('products')

  const longDesc = useCallback((longDescription: string) => {
    if (longDescription === undefined) return ''
    return longDescription.length < 70 ? `${longDescription.replace(/&lt;/g, '<').replace(/&gt;/g, '<')}` : `${longDescription.replace(/&lt;/g, '<').replace(/&gt;/g, '<').substring(0, 80)}...`
  }, [])

  const cards = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!(data)) return []
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return data.data.filter((data: any) => data.skus.length > 0)
  }, [data])

  if (data === undefined || data === null) {
    return <PageLoaderComponent />
  }

  return (
    <Template>
      <SectionHeaderComponent
        pretitle="Pesquise o que você quiser!"
        title="Categorias"
      />

      <S.GridContainer>
        <CategoryCard
          bgImgSrc={bgImg1}
          description="Navegue por todos os itens"
          itemCount="1360"
          title="Todos os itens"
        />

        <CategoryCard
          bgImgSrc={bgImg2}
          description="A melhor seleção"
          itemCount="254"
          title="Casa e Cozinha"
          to="/3359"
        />

        <CategoryCard
          bgImgSrc={bgImg3}
          description="Produtos Digitais"
          itemCount="1207"
          title="Gift cards"
          to="/5232"
        />

        <CategoryCard
          bgImgSrc={bgImg4}
          description="Notebooks, videogames..."
          itemCount="153"
          title="Eletrônicos"
          to="/3285"
        />
      </S.GridContainer>

      <SectionHeaderComponent
        headerActions={[{ title: 'Mostrar todos os itens', url: '/marketplace-category' }]}
        pretitle="Veja as novidades!"
        title="Últimos itens"
      />

      <S.GridContainer>
        {cards.map(({ id, category, code, description, name, skus }: ProductData) => {
          return <CardMarketplace
            key={code}
            itemCode={id}
            category={category.name}
            description={longDesc(description)}
            postedBy="Marina Valentine"
            price={0}
            rating="4"
            title={name}
            skus={skus}
          />
        })}
      </S.GridContainer>
    </Template >)
}
