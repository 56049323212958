import axios from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { toast } from 'react-toastify'
import PageLoaderComponent from '../../components/PageLoader'
import PostCard from '../../components/PostCard'
import GoogleSocialButton from '../../components/SocialButton/Google'
import { useAuth } from '../../contexts/AuthContext'
import { useModal } from '../../contexts/ModalContext'
import api from '../../services/api'
import Template from '../../template'
import * as S from './style'
export default function Perfil (props) {
  const { user, doLogout, userCan } = useAuth()
  const [dashboardData, setDashboardData] = useState({})
  const [posts, setPosts] = useState()

  const loadDashboard = useCallback(async () => {
    try {
      const response = await api.get('/dashboard')
      setDashboardData(response.data)
      setPosts(response.data.posts)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          toast.error('O token expirou', { theme: 'colored' })
          doLogout()
        }
      }
    }
  }, [doLogout])

  const { openModal } = useModal()

  const isLoaded = useMemo(() => user && dashboardData, [dashboardData, user])

  const openGoogleModal = useCallback(() => {
    if (localStorage.getItem('modal-google')) return
    openModal({
      header: 'Associe sua conta Google!',
      body: <GoogleSocialButton userId={user.id} />
    })
    localStorage.setItem('modal-google', 1)
  }, [openModal, user])

  useEffect(() => {
    loadDashboard()
    // openGoogleModal()

  }, [loadDashboard, openModal, user])

  if (!isLoaded) return <PageLoaderComponent />

  return (
    <S.HomeContainer>
      <Template>
        <div className="grid mt-0">
          <S.Grid>

            {userCan('blog:read') && (
              <S.GridPosts>
                {posts &&
                  posts.map((post) => <PostCard key={post.id} {...post} />)}
              </S.GridPosts>
            )}

            <iframe
              src="https://www.youtube.com/embed/ckwtX9YxeAM?si=8ajlRyPKDbhTqM86"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </S.Grid>
        </div>
      </Template>
    </S.HomeContainer>
  )
}
