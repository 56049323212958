import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useCampaign } from '../../contexts/CampaignContext'
import * as S from './style'

function FooterComponent (): ReactElement {
  const { campaignData } = useCampaign()
  return <S.Footer>
    <S.Logo src={campaignData.logo} alt="logo liga gigantes da execução" />
    <div className="footer-links">
      {campaignData.dataPrivacyLink !== '' && <a href={campaignData.dataPrivacyLink} target="_blank" rel="noreferrer">Requerimentos sobre Privacidade de Dados</a>}
      {campaignData.privacyLink !== '' && <a href={campaignData.privacyLink} target="_blank" rel="noreferrer">Aviso de Privacidade</a>}
      <Link to="/regulamento">Regulamento</Link>
      <Link to="/terms">Termos de uso</Link>
    </div>
  </S.Footer>
}

export default FooterComponent
