import styled from 'styled-components'

export const Container = styled.div`
  .xm-tooltip {
    white-space: nowrap;
    position: absolute;
    z-index: 99999;
    top: -28px;
    left: 50%;
    margin-left: -24px;
    opacity: 0;
    visibility: hidden;
    transform: translate(0px, 10px);
    transition: all 0.3s ease-in-out 0s;
  }
`
