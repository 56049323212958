import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import ErrorComponent from '../ErrorComponent'
import * as S from './style'

interface InputTextProps {
  as?: 'input' | 'textarea'
  name?: string
  id?: string
  label?: string
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  readonly?: boolean
  type?: string
  mask?: (value: string) => string
  error?: string
  large?: boolean
  maxLength?: number
  required?: boolean
  className?: string
  children?: ReactNode
  disabled?: boolean
}

export default function InputText ({
  as = 'input',
  name,
  id,
  label,
  value,
  onChange,
  placeholder,
  readonly,
  type,
  mask,
  error,
  large,
  maxLength,
  required,
  className,
  children,
  disabled
}: InputTextProps): ReactElement {
  const [internalValue, setInternalValue] = useState<string>()
  const [isActive, setIsActive] = useState<boolean>(false)

  const isDirty = useMemo(() => {
    if (isActive || (internalValue !== '' && internalValue !== undefined)) return true

    return false
  }, [isActive, internalValue])

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = e.target.value
      if (mask !== null && mask !== undefined) value = mask(value)
      if (onChange !== null && onChange !== undefined) onChange(value)
      setInternalValue(value)
    },
    [onChange, mask]
  )

  const isSmall = useMemo(() => !(large ?? false), [large])

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  return (
    <S.Container
      className={`form-input ${isSmall ? 'small' : ''} ${isDirty ? 'active' : ''
        }`}
    >
      <label htmlFor={id}>{label}</label>
      <S.Input
        as={as}
        className={className}
        type={type ?? 'text'}
        id={id}
        name={name}
        value={internalValue !== null && internalValue !== undefined && internalValue !== '' ? internalValue : '' }
        onChange={handleValueChange}
        placeholder={placeholder}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        readOnly={readonly}
        required={required}
        maxLength={maxLength}
        disabled={disabled}
      />
      {children}
      <ErrorComponent>{error}</ErrorComponent>
    </S.Container>
  )
}
