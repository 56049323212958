import InputSelect from '../Forms/InputSelect';


function CategoryComponent ({ error, ...rest }) {

  return <>
    <InputSelect {...rest} name="category" label="Categoria" error={(Boolean(error)) && error.categoryId} >
      <option disabled selected={!Boolean(rest.value)}>Selecione a categoria</option>
      <option selected={rest.value === 1} value={1}>Iron</option>
      <option selected={rest.value === 2} value={2}>Bronze</option>
      <option selected={rest.value === 3} value={3}>Silver</option>
      <option selected={rest.value === 4} value={4}>Gold</option>
      <option selected={rest.value === 5} value={5}>Gold Alliance</option>
    </InputSelect>
  </>

}

export default CategoryComponent;
