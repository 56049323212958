import { ReactElement, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import schemaAddress from '../../config/userFormAddressScheme.json'
import schema from '../../config/userFormScheme.json'
import { useAuth } from '../../contexts/AuthContext'
import { FormValidator } from '../../domain/FormValidator'
import RepublicaGetAddress from '../../domain/RepublicaGetAddress'
import api from '../../services/api'
import { MaskCEP, MaskDocument, MaskPhone } from '../../utils/mask'
import InputText from '../Forms/InputText'
import * as S from './style'

const formValidator = new FormValidator(schema)
const formValidatorAddress = new FormValidator(schemaAddress)
const republicaGetAddress = new RepublicaGetAddress()

interface UserUpdateModalType {
  onSave: () => void
}

interface UserType {
  id?: number
  name?: string
  email?: string
  phone?: string
  cnpj?: string
  region?: {
    id: number
    name: string
  }
  regionId?: number
  holding?: string
  cargo?: string
  document?: string
  birthday?: string
  cellphone?: string
  cep?: string
  estado?: string
  cidade?: string
  bairro?: string
  logradouro?: string
  numero?: string
  complemento?: string
}

export function UserUpdateModal ({ onSave }: UserUpdateModalType): ReactElement {
  const [user, setUser] = useState<UserType>()
  const [errors, setErrors] = useState<UserType>()
  const { userCan } = useAuth()
  const handleSave = useCallback(
    async (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault()
      let hasError = false
      if (!formValidator.validate(user)) {
        setErrors(formValidator.errors)
        hasError = true
      }
      if (userCan('addresses:update', true) as boolean) {
        if (!formValidatorAddress.validate(user)) {
          setErrors({ ...errors, ...formValidatorAddress.errors })
          hasError = true
        }
      }
      if (hasError) return
      try {
        await api.put(`/user/${String(user?.id)}`, user)
        onSave()
      } catch (err: any) {
        if (err.response === undefined || err.response === null || err.response === false) {
          console.log(err)
          return
        }
        switch (err.response.statuCode) {
          case 403:
            break
          case 400:
            toast.error(err.response.data, { theme: 'colored' })
            break
          default:
            toast.error(
              'Ocorreu um erro interno no servidor, por favor tente novamente',
              { theme: 'colored' }
            )
            break
        }
      }
    },
    [user, userCan, errors, onSave]
  )

  const loadMe = useCallback(async () => {
    const response = await api.get('/me')
    setUser({
      ...response.data.address,
      ...response.data
    })
  }, [])

  const handleUpdateCep = useCallback(async (cep: string) => {
    setUser((user) => ({ ...user, cep }))
    if (cep.length === 9) {
      const {
        uf: estado,
        cidade,
        logradouro,
        bairro
      } = await republicaGetAddress.getAddress(cep)
      setUser((user) => ({ ...user, estado, cidade, logradouro, bairro }))
    }
  }, [])

  useEffect(() => {
    loadMe()
  }, [loadMe])

  return (
    <form onSubmit={handleSave}>
      <S.Row>
        <InputText
          name="name"
          label="Nome completo"
          value={user?.name ?? ''}
          onChange={(name: string) => setUser({ ...user, name })}
          error={errors?.name}
        />
      </S.Row>
      <S.Row>
        <InputText
          name="email"
          label="E-mail corporativo"
          value={user?.email ?? ''}
          onChange={(email) => setUser({ ...user, email })}
          error={errors?.email}
        />
        <InputText
          name="birthday"
          label="Data de nascimento"
          value={user?.birthday ?? ''}
          onChange={(birthday) => setUser({ ...user, birthday })}
          error={errors?.birthday}
          type="date"
        />
      </S.Row>
      <S.Row>
        <InputText
          name="cellphone"
          label="Telefone Corporativo"
          value={user?.cellphone ?? ''}
          onChange={(cellphone) => setUser({ ...user, cellphone })}
          error={errors?.cellphone}
          mask={MaskPhone}
        />
        <InputText
          placeholder=""
          name="document"
          label="CNPJ da empresa"
          value={user?.document ?? ''}
          onChange={(document) => setUser({ ...user, document })}
          error={errors?.document}
          mask={MaskDocument}
        />
      </S.Row>
      <S.Row>
        {userCan('addresses:update', true) as boolean && (
          <InputText
            name="cep"
            label="CEP da empresa"
            value={user?.cep ?? ''}
            onChange={handleUpdateCep}
            error={errors?.cep}
            mask={MaskCEP}
          />
        )}
      </S.Row>
      {userCan('addresses:update', true) as boolean && (
        <>
          <S.Row>
            <InputText
              name="estado"
              label="Estado da empresa"
              value={user?.estado ?? ''}
              onChange={(estado: string) => setUser({ ...user, estado })}
              error={errors?.estado}
            />
            <InputText
              name="cidade"
              label="Cidade da empresa"
              value={user?.cidade ?? ''}
              onChange={(cidade: string) => setUser({ ...user, cidade })}
              error={errors?.cidade}
            />
          </S.Row>
          <S.Row>
            <InputText
              name="bairro"
              label="Bairro da empresa"
              value={user?.bairro ?? ''}
              onChange={(bairro) => setUser({ ...user, bairro })}
              error={errors?.bairro}
            />
            <InputText
              name="logradouro"
              label="Rua da empresa"
              value={user?.logradouro ?? ''}
              onChange={(logradouro) => setUser({ ...user, logradouro })}
              error={errors?.logradouro}
            />
          </S.Row>
          <S.Row>
            <InputText
              name="numero"
              label="Numero da empresa"
              value={user?.numero ?? ''}
              onChange={(numero) => setUser({ ...user, numero })}
              error={errors?.numero}
            />
            <InputText
              name="complemento"
              label="Complemento da empresa"
              value={user?.complemento ?? ''}
              onChange={(complemento) => setUser({ ...user, complemento })}
              error={errors?.complemento}
            />
          </S.Row>
        </>
      )}
      <button type="submit" className="button secondary">
        Salvar
      </button>
    </form>
  )
}
