import { useAuth } from '../../contexts/AuthContext';
import Template from '../../template';
import * as S from './style';

export default function Reports (props) {
  const { userCan } = useAuth();
  const externalDashboardUrl = 'https://app.powerbi.com/groups/cd09b29e-c6a8-49dc-98d3-3a1a391f02b7/reports/ecb5e758-d2cd-490f-a8c5-bd9560922f6b/ReportSection?experience=power-bi'

  return (
    <Template>
      <div className="grid medium-space mt-0 min-vh-100">
        <S.Grid className='mt-0'>
          <S.GridFastAcccess>
            {userCan('update:results') && <S.FastLink to="/admin/results" className="product-category-box category-all results" >
              <p className="product-category-box-title">Importar Classificações</p>
              <p className="product-category-box-text">Importar mudanças de Classificação</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('system:manage') && <S.FastLink to="/admin/quiz" className="product-category-box category-all" >
              <p className="product-category-box-title">Criar Quiz</p>
              <p className="product-category-box-text">Crie ou edite um quiz!</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('system:manage') && <S.FastLink to="/admin/room" className="product-category-box category-all" >
              <p className="product-category-box-title">Lives</p>
              <p className="product-category-box-text">Crie novas lives!</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('post:create') && <S.FastLink to="/admin/blog" className="product-category-box category-all" >
              <p className="product-category-box-title">Admin Blog</p>
              <p className="product-category-box-text">Poste uma nova notícia</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('update:banners') && <S.FastLink to="/admin/banner" className="product-category-box category-all" >
              <p className="product-category-box-title">Admin Banner</p>
              <p className="product-category-box-text">Gestão de banners</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('users:update') && <S.FastLink to="/admin/usuarios" className="product-category-box category-all" >
              <p className="product-category-box-title">Usuários</p>
              <p className="product-category-box-text">Consultar e editar cadastro de usuários</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('users:approve') && <S.FastLink to="/admin/not-approved" className="product-category-box category-all" >
              <p className="product-category-box-title">Aprovar Usuários</p>
              <p className="product-category-box-text">Aprovar usuários</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('user-status:export') && <S.FastLink to="/admin/reports" className="product-category-box category-all" >
              <p className="product-category-box-title">Extração de relatórios</p>
              <p className="product-category-box-text">Exporte os dados da campanha!</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('read:dashboard-geral') && <S.FastLink to={externalDashboardUrl} target="_blank" rel="noopener" className="product-category-box category-all" >
              <p className="product-category-box-title">Dashboard Geral</p>
              <p className="product-category-box-text">Informações gerais da campanha</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('system:manage') && <S.FastLink to="/admin/notice" className="product-category-box category-all" >
              <p className="product-category-box-title">Postar notícia</p>
              <p className="product-category-box-text">Enviar uma nova notícia</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('homolog-ranking:read') && <S.FastLink to="/ranking/homolog" className="product-category-box category-all" >
              <p className="product-category-box-title">Ver o ranking</p>
              <p className="product-category-box-text">Ver qualquer ranking do site</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

            {userCan('update:regulation') && <S.FastLink to="/regulamento/edit" className="product-category-box category-all" >
              <p className="product-category-box-title">Editar o regulamento</p>
              <p className="product-category-box-text">Editar o regulamento</p>
              <button type="button" className="product-category-link"><span>Acesse</span></button>
            </S.FastLink>}

          </S.GridFastAcccess>
        </S.Grid>
      </div>
    </Template >
  );
}
