import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import { createGlobalStyle, css } from 'styled-components'
import '../assets/css/styles.min.css'
import '../assets/css/vendor/bootstrap.min.css'
import background from '../assets/img/landing/landing-background.jpg'
import '../assets/js/utils/svg-loader.js'
import GilroyBold from '../fonts/Gilroy-Bold.ttf'
import GilroyMedium from '../fonts/Gilroy-Medium.ttf'

const fonts = css`
    @font-face {
        font-family: 'Gilroy-Bold';
        src: local('Gilroy-Bold'), url(${GilroyBold}) format('truetype');
    }

    @font-face {
        font-family: 'Gilroy-Medium';
        src: local('Gilroy-Medium'), url(${GilroyMedium}) format('truetype');
    }
`
const root = css`
:root {
  --primary: #008350;
  --primary-opacity: rgba(0, 131, 80, 0.7);
  --secondary: #1DF161;
  --button-primary: #008350;
  --button-primary-hover: #1DF161;
  --text-light: #008350CC;
  --gray-light: #EBF3EF;

  --danger: #dc3545;
  --success: #28a745;
  --info: #615dfa;

  --hover: #1DF161;

  --background-image: url(${background});

  --font-bold: 'Gilroy-Bold',sans-serif;
}
`

const rootColors = { default: root }

const GlobalStyle: any = createGlobalStyle`
${fonts}
${rootColors.default}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--gray-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-opacity);
}

html {
    scroll-behavior: smooth;
}

button {
    cursor: pointer;
    &.button.primary {
        background-color: var(--primary);
    }
    &.button.primary:hover {
        background-color: var(--hover);
    }
}

[disabled] {
    opacity: 0.6;
    cursor: not-allowed
}

button[aria-label="close"] {
    display: none;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    overflow: hidden;
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
}

.content-grid {
    transition: transform 0.4s ease-in-out 0s;
    margin: 0 auto;
    width: 100%;
}

.landing-form {
    margin-top: 50px;
}

.form__input__focus:focus ~ .form__label,
.form__input__focus:not(:placeholder-shown).form__input__focus:not(:focus) ~ .form__label {
    background-color: var(--white);
    padding: 0 6px;
    font-size: 0.75rem;
    top: -6px;
    left: 12px;
}

.hexagon-image-30-32 {
    border-radius: 50%;
    overflow: hidden;
    padding-bottom: calc(100% - 6px);
    border: 3px solid #5d6ffb;
}

.hexagon-image-30-32 img {
    max-width: 100%;
    height: auto;
}

.user-avatar.online:before {
    background-color: var(--success);
    margin-left: 3px;
    margin-top: 1px;
}

.perfect-circle {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.user-avatar-content {
    width: 100%;
}

.perfect-circle.large {
    padding-bottom: calc(100% - 10px);
    border-radius: 50%;
    border: 5px solid #5d6ffb;
}

.perfect-circle img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
}

.user-avatar-border {
    width: 100%;
}

.overlay {
    width: 100%; height: 100%; background-color: rgba(21, 21, 31, 0.96); position: fixed; top: 0px; left: 0px; z-index: 100000; opacity: 0; visibility: hidden; transition: opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
}

.pop-box {
    position: absolute; left: 50%; z-index: 100001; opacity: 0; visibility: hidden; transform: translate(0px, -40px); transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s; top: 0px; margin-left: -192px;
}

.Toastify {
    z-index: 100001;
    position: fixed;
}

.visible {
    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(21, 21, 31, 0.96);
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 100000;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
    }

    .popup-box {
        position: absolute; left: 50%; z-index: 100001; opacity: 1; visibility: visible; transform: translate(0px, 0px); transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s; top: 100px; margin-left: -192px;
    }
}

.landing {
    background-image: var(--background-image);
    @media (min-width: 1600px) {
        background-size: cover;
    }
    @media (max-width: 960px) {
        &&& {
            /* background-size: 250% 100%; */
            background-position: center;
        }
    }
    @media (max-width: 1599px) {
        background-size: cover;
        background-position: 5% 30%;
    }
}

::after, ::before {
    display: block;
}
input[type="email"]::placeholder, input[type="text"]::placeholder {
    color: #adafca;
    font-size: .875rem;
    font-weight: 500;
}
::selection {
    color: var(--white);
    background-color: var(--secondary);
}

input[type="email"], input[type="text"] {
    height: 54px;
    padding: 0 18px;
}
input[type="email"], input[type="text"], select, textarea {
    background-color: var(--white);
    border: 1px solid #dedeea;
    color: var(--dark);
    transition: border-color .2s ease-in-out;
}
button, input[type="email"], input[type="text"], select, textarea {
    width: 100%;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 700;
}
button, input[type="email"], input[type="text"], label, select, textarea {
    font-family: 'Gilroy-Medium', sans-serif;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

p {
    font-size: 16px;
}

.blocked {
    filter: grayscale(1) !important;
    color: #aeaeae !important;
}
.cursor-pointer {
    cursor: pointer;
}

.account-hub-content {
    margin-top: 1rem;
}

.album-preview .album-preview-info:hover {
    box-shadow: inset 0 -120px 50px -40px var(--primary);
}

@media (max-width: 900px) {
    .header .header-actions.search-bar .interactive-input input {
        height: 100%;
    }
    .content-grid {
        padding: 112px 40px 100px;
    }
}

@media (max-width: 680px) {
    .header .header-actions:last-child {
        display: flex;
    }
    .header .header-actions.search-bar .interactive-input input {
        height: 100%;
    }
    .content-grid {
        padding: 112px 0 100px;
    }
}

@media (max-width: 460px) {
    .grid.grid-3-3-3, .grid.grid-3-3-3-3 {
        grid-template-columns: repeat(2,auto) !important;
    }
    &&& {
        .content-grid {
            padding: 112px 0 100px;
        }
    }
}

@keyframes image-move {
from {
    transform: rotate(15deg) translate(15px, -25px);
}
to {
    transform: rotate(25deg) translate(15px, -25px);
}
}

.vibrate-1 {
    animation: vibrate-1 0.3s linear infinite both;
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

.shake-horizontal {
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

`

export default GlobalStyle
