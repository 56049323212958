import { ReactElement, useCallback, useEffect, useState } from 'react'
import api from '../../services/api'
import InputText from '../Forms/InputText'
import * as S from './style'

interface OptionProps {
  name: string
  email: string
  id: string
}

interface MultiSelectProps {
  parentList: OptionProps[]
  onChange: (values: any[]) => void
}

function MultiSelect ({ parentList, onChange }: MultiSelectProps): ReactElement {
  const [userList, setUserList] = useState<OptionProps[]>([])
  const [resultsUserList, setResultsUserList] = useState<OptionProps[]>([])

  const isParent = useCallback((option: OptionProps) => {
    const index = parentList.findIndex(parent => parent.id === option.id)
    return index >= 0
  }, [parentList])

  const handleRemoveOption = useCallback((option: any) => {
    const newOptionsSelected = parentList.filter(s => s.id !== option.id)
    onChange(newOptionsSelected)
  }, [parentList])

  const handleSelectOption = useCallback((option: any) => {
    if (isParent(option)) {
      handleRemoveOption(option)
      return
    }
    onChange([...parentList, option])
  }, [parentList])

  const handleSearch = useCallback((keyword: string) => {
    const filteredList = userList.filter(option => {
      return option.name.toLowerCase().includes(keyword.toLowerCase()) || option.email?.toLowerCase().includes(keyword.toLowerCase())
    })
    setResultsUserList(filteredList)
  }, [userList, resultsUserList])

  useEffect(() => {
    (async () => {
      const response = await api.get('/users-parents')
      setUserList(response.data.users)
      setResultsUserList(response.data.users)
    })()
  }, [])

  return <S.MultiSelect>
    <div className='label'>Hierarquia: selecionar superiores</div>
    <S.List>
      <S.SearchContainer>
        <InputText onChange={handleSearch} placeholder='Pesquise o nome ou e-mail do usuário' />
        <div className='search-icon'></div>
      </S.SearchContainer>
      <S.ListResults>
        {resultsUserList.map((option, index) => (
          <S.Option key={index} onClick={() => handleSelectOption(option)} $isSelected={isParent(option)}>
            {option.name + ' (' + (option.email === null ? 'E-mail não cadastrado' : option.email) + ')'}
          </S.Option>
        ))
        }
      </S.ListResults>
    </S.List>
    <S.SelectedOptions>
      {parentList.map((option, index) => (
        <S.SelectedOption key={index}>
          {option.name + ' (' + (option.email === null ? 'E-mail não cadastrado' : option.email) + ')'}
          <div className='remove-option' onClick={() => handleRemoveOption(option)}>
            &times;
          </div>
        </S.SelectedOption>
      ))}
    </S.SelectedOptions>
  </S.MultiSelect>
}

export default MultiSelect
