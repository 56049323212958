import styled from 'styled-components';

export const Dropbox = styled.div`
position: absolute;
z-index: 9999;
width: min(90vw, 384px);
top: 64px;
right: 6px;
opacity: ${props => props.active ? 1 : 0};
visibility: ${props => props.active ? 'visible' : 'hidden'} ;
transform: ${props => props.active ? 'translate(0px)' : 'translate(0px, -40px)'};
transition: transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s;
.dropdown-navigation-link {
  color: var(--primary);
}
.dropdown-navigation-link:hover {
  color: var(--secondary);
}
@media(max-width: 640px) {
  max-width: 15rem;
  &.notice-box {
    right: -60px;
    max-width: unset;
  }
}
`;

export const HeaderContainer = styled.div`
 @media(max-width: 960px) {
   &&& header > div.header-actions > .header-actions {
     display: block;
   }
   .sidemenu-trigger {
    display: none;
   }
 }
`;

export const LeaveButton = styled.button`
i {
  margin-right: 16px;
}
`;

export const MenuItems = styled.ul`
display: flex;
align-items: center;
justify-content: space-between;
min-width: min(80vw,900px);
width: 100%;
`;

export const Tooltip = styled.div`
visibility: hidden;
`;

export const Li = styled.li`
${Tooltip} {
  display: none;
  position: absolute;
}
&:hover {
  ${Tooltip} {
    visibility: visible;
    display: block;
    white-space: nowrap;
    position: absolute;
    z-index: 99999;
    left: 0;
    top: 100%;
    margin-top: 0px;
    opacity: 1;
    transition: all 0.3s ease-in-out 0s;
  };
}
&&& {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 0;
  p {
    color: var(--white);
  }
  .menu-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 0;
    .menu-item-link-icon {
      position: static;
      padding-left: 0;
      fill: var(--white);
    }
  }
  .menu-item-link:hover {
      background-color: var(--white);
      padding-left: 0;
      div.menu-item-link-icon > p {
        color: var(--secondary);
        fill: var(--secondary);
      }
    }
  }
`;

export const ModalGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 32px;
`;

export const Logo = styled.img`
  max-height: 56px;
`