import { ReactElement } from 'react'
import defaultAvatar from '../../../assets/img/avatar.png'
import funny from '../../../assets/img/reaction/funny.png'
import like from '../../../assets/img/reaction/like.png'
import love from '../../../assets/img/reaction/love.png'
import HexagonComponent from '../../Hexagon'
import ReactionItemComponent from './ReactionItemComponent'
import * as S from './style'

export default function CardContentActionComponent (): ReactElement {
  const reactionCount = 9
  const commentCount = 2
  const participantCount = 11
  return <S.Container>
    <div className="content-action">
      <div className="meta-line">
        <div className="meta-line-list reaction-item-list">
          <ReactionItemComponent icon={funny} />
          <ReactionItemComponent icon={like} />
          <ReactionItemComponent icon={love} />
        </div>

        <p className="meta-line-text">{reactionCount}</p>
      </div>

      <div className="meta-line">
        <div className="meta-line-list user-avatar-list">
          <div className="user-avatar micro no-stats">
            <HexagonComponent className={''} size="small" src={defaultAvatar} />
          </div>
          <div className="user-avatar micro no-stats">
            <HexagonComponent className={''} size="small" src={defaultAvatar} />
          </div>
          <div className="user-avatar micro no-stats">
            <HexagonComponent className={''} size="small" src={defaultAvatar} />
          </div>
          <div className="user-avatar micro no-stats">
            <HexagonComponent className={''} size="small" src={defaultAvatar} />
          </div>
          <div className="user-avatar micro no-stats">
            <HexagonComponent className={''} size="small" src={defaultAvatar} />
          </div>
        </div>

        <p className="meta-line-text">{participantCount} Participantes</p>
      </div>
    </div>

    <div className="content-action">
      <div className="meta-line">
        <p className="meta-line-link">{commentCount} Comentário</p>
      </div>
    </div>
  </S.Container>
}
