import { useEffect } from 'react';
import CardWidget from '../../components/CardWidget';
import UserStatus from '../../components/UserStatus';
import { useNotify } from '../../contexts/NotifyContext';
import * as S from './style';

export default function Newsfeed () {
  const { notices, markAllAsReady } = useNotify();
  useEffect(() => {
    const unread = notices.find((notice) => !notice.isRead);
    if (unread) markAllAsReady();
  });
  return (
    <>
      <div className="grid mobile-prefer-content">
        <div className="grid-column">
          {notices.length > 0 && notices.map(notice => <>
            <CardWidget content={notice} key={notice.id} status={<UserStatus user={notice.sender} createdAt={notice.created_at} />} message={<div dangerouslySetInnerHTML={{ __html: notice.message }}></div>}></CardWidget>
          </>
          )}
          {notices.length === 0 && <S.Center>Você ainda não tem nenhuma mensagem</S.Center>}
        </div>
      </div>
    </>
  );
}
