import { ReactElement, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import TextEditor from '../../../components/TextEditor'
import api from '../../../services/api'
import Template from '../../../template'

function RegulamentoEdit (): ReactElement {
  const [regulation, setRegulation] = useState<string>('')

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/regulations')
        const regulationIndex = response.data.findIndex((c: { id: number }) => c.id === 1)
        setRegulation(response.data[regulationIndex].content)
      } catch (error) {
        console.error(error)
        toast.error('Não foi possível carregar o regulamento. Por favor, tente novamente.', {
          theme: 'colored'
        })
      }
    })()
  }, [])
  return <Template>
    <div className="grid medium-space">
      <div className="account-hub-content">
      <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Regulamento</p>
            <h2 className="section-title">{'Editar regulamento'}</h2>
          </div>
        </div>
        <div className="grid-column">
          <TextEditor initialHTMLContent={regulation} serverUrlToUpdate='/regulations/1' />
        </div>
      </div>
    </div>
  </Template>
}

export default RegulamentoEdit
