import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageLoader from '../../components/PageLoader'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import Template from '../../template'
import AccountStatBox from './AccountStatBox'
import DashboardChart from './DashboardChart'
import DashboardTable from './DashboardTable'
import Grid from './Grid'
import { User } from '../Perfil'

import * as S from './styled'

interface Params {
  [k: string]: string | number
}

export interface Filter {
  attribute: string
  values: Array<{
    id: string
    name: string
  }>
}

function Resultados (): ReactElement {
  const { userId } = useParams()
  const { user } = useAuth() as { user: User }
  const [data, setData] = useState<any[]>([])
  const [params, setParams] = useState<Params>({ stepId: 1 })

  const loadDashboardData = useCallback(async (params: Params) => {
    if (userId !== undefined) {
      const response = await api.get(`/result/${userId}`, { params })
      setData(response.data.content)
      return
    }
    const response = await api.get(`/result/${user.id}`, { params })
    setData(response.data.content)
  }, [userId, user])

  const handleChangeFilter = useCallback((filter: Filter, value: string) => {
    setParams((params) => ({ ...params, ...{ [filter.attribute]: value } }))
  }, [])

  useEffect(() => {
    loadDashboardData(params)
  }, [loadDashboardData, params])

  if (data.length === 0) return <PageLoader />

  return <Template>
    <Grid>
      {data.length > 0
        ? data.map((component, index) => {
          switch (component.component) {
            case 'stats':
              return <AccountStatBox {...component} key={index}>
                {(component.icon === null || component.icon === undefined) && <S.Graph value={component.title} />}
              </AccountStatBox>
            case 'table':
              return <DashboardTable
                {...component}
                onChange={(value: string) => handleChangeFilter(component.filter, value)}
                key={index}
              />
            case 'chart':
              return <DashboardChart chartData={component} key={index} />
            case 'break':
              return <S.Break key={index} />
            default:
              return ''
          }
        })
        : <h5>Não existe resultado para você</h5>}
    </Grid>
  </Template>
}

export default Resultados
