/* eslint-disable space-before-function-paren */
/* eslint-disable react/react-in-jsx-scope */
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useCampaign } from '../../contexts/CampaignContext';
import { useMenu } from '../../contexts/DesktopMenuContext';
import { useClickOutside } from '../../hooks/useClickOutside';
import CartComponent from './CartComponent';
import MobileMenu from './MobileMenu';
import NoticeGroup from './NoticeGroup';
import * as S from './style';

export default function Header () {
  const [activeSettings, setActiveSettings] = useState(false);
  const [wrapperRef] = useClickOutside(() => setActiveSettings(false));
  const { userCan, doLogout } = useAuth();
  const { setOpen } = useMenu();
  const { campaignData } = useCampaign()

  const toggleSettings = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveSettings(!activeSettings);
  }, [activeSettings]);

  const toggleMenu = useCallback(() => {
    setOpen(open => !open);
  }, [setOpen]);

  return (
    <S.HeaderContainer>
      <header className="header">
        <div className="header-actions">
          <MobileMenu />
          <div className="sidemenu-trigger navigation-widget-trigger" onClick={toggleMenu}>
            <svg className="icon-grid">
              <use xlinkHref="#svg-grid"></use>
            </svg>
          </div>
        </div>
        <nav className="header-actions">
          <div className="header-brand">
            <div className="logo">
              <Link to="/home">
                <S.Logo src={campaignData.logo} alt="Logo da campanha" />
              </Link>
            </div>
          </div>
        </nav>
        <div className="header-actions">
          {userCan('marketplace') && <CartComponent />}

          {userCan('newsfeed:read') && <div className="action-list">
            <NoticeGroup />
          </div>}

          <div className="action-item-wrap">
            <div className="action-item dark header-settings-dropdown-trigger" onClick={toggleSettings} >
              <svg className="action-item-icon icon-settings">
                <use href="#svg-settings"></use>
              </svg>
            </div>

            <S.Dropbox active={activeSettings} className="dropdown-navigation header-settings-dropdown" ref={wrapperRef}>

              <Link className="dropdown-navigation-link" to="/perfil">Meu perfil</Link>
              <Link className="dropdown-navigation-link" to="/regulamento">Regulamento</Link>
              {userCan('control:removed-item') && <Link className="dropdown-navigation-link" to="/fale-conosco">Fale conosco</Link>}
              <Link className="dropdown-navigation-link" to="/terms">Termos de Uso</Link>

              <S.LeaveButton
                onClick={doLogout}
                className="dropdown-navigation-button button small secondary"
                type="button"
              >
                <i className="fas fa-door-closed"></i>
                <span>Sair</span>
              </S.LeaveButton>
            </S.Dropbox>
          </div>
        </div>
      </header>
    </S.HeaderContainer>
  );
}
