import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import InputText from '../../components/Forms/InputText';
import InputSelect from '../../components/Forms/InputSelect';
import Template from '../../template';
import * as S from './style';
import { useAuth } from '../../contexts/AuthContext';
import { useModal } from '../../contexts/ModalContext';
import whatsapp from '../../assets/img/whatsapp.png';

function FaleConosco () {
  const [message, setMessage] = useState();
  const [saving, setSaving] = useState(false);
  const { openModal } = useModal();
  const { user } = useAuth();

  const clearMesasageData = useCallback(() => {
    setMessage({ ...user });
  }, [user]);

  const handleSend = useCallback(async (evt) => {
    evt.preventDefault();
    setSaving(true);
    try {
      await api.post('/message', message);

      openModal({ body: <div className='form-row'>Nosso horário de atendimento é das 9h às 18h, de segunda a sexta, exceto feriados. Nosso tempo de resposta será de até 48h.</div>, title: 'Sucesso!' });
      clearMesasageData();
    } catch (err) {
      openModal({ body: <div className='form-row'>Ocorreu um erro, por favor tente novamente mais tarde!</div>, title: 'Erro!' });
    }

    setSaving(false);
  }, [openModal, message, clearMesasageData]);

  const handleMessage = useCallback((evt) => {
    setMessage((msg) => ({ ...msg, msg: evt.target.value }));
  }, []);

  useEffect(() => {
    clearMesasageData();
  }, [clearMesasageData]);

  if (!message) return <></>;

  return <Template>
    <div style={{ marginTop: '8rem' }}></div>

    <div className="grid medium-space">
      <div className="account-hub-content">
        <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Fale conosco</p>

            <h2 className="section-title">Envie uma mensagem para nós!</h2>
          </div>
        </div>

        <div className="grid-column">
          <div className="widget-box">
            <p className="widget-box-title">Fale conosco</p>

            <div className="widget-box-content">
              <form className="form" action="" method="POST" onSubmit={handleSend}>
                <S.Fields>
                  <InputText label="Nome Completo" value={message.name} onChange={(v) => setMessage((msg) => ({ ...msg, name: v }))} />
                  <InputText label="Email Corporativo" value={message.email} onChange={(v) => setMessage((msg) => ({ ...msg, email: v }))} />
                  <InputText label="Telefone Corporativo" value={message.cellphone} onChange={(v) => setMessage((msg) => ({ ...msg, cellphone: v }))} />
                  <InputText label="Regional" value={message.regional} onChange={(v) => setMessage((msg) => ({ ...msg, regional: v }))} />

                  <InputSelect label="Assunto" value={message.assunto} onChange={(v) => setMessage((msg) => ({ ...msg, assunto: v }))}>
                    <option value="" selected={message.assunto === ''}>Selecione um assunto</option>
                    <option value="Sugestão" selected={message.assunto === 'Sugestão'}>Sugestão</option>
                    <option value="Reclamação" selected={message.assunto === 'Reclamação'}>Reclamação</option>
                    <option value="Contribuição" selected={message.assunto === 'Contribuição'}>Contribuição</option>
                    <option value="Dúvidas" selected={message.assunto === 'Dúvidas'}>Dúvidas</option>
                  </InputSelect>
                </S.Fields>
                <div className="form-row split">
                  <div className="form-item">
                    <S.TextAreaContainer>
                      <S.TextAreaLabel htmlFor="message">Mensagem</S.TextAreaLabel>
                      <S.TextArea name="message" id="message" rows="10" onChange={handleMessage} value={message.msg || ''} />
                    </S.TextAreaContainer>
                  </div>
                </div>

                <div className="form-row split">
                  <div className="form-item">
                    <button
                      type="submit"
                      className="button primary add-field-button"
                      style={{ marginTop: '0', float: 'right' }}
                      disabled={saving}
                    >{saving ? 'Enviando...' : 'Enviar'}</button>
                  </div>

                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>

    <S.FloatIcon href="https://wa.me/5511988637184" target="_blank">
      <img src={whatsapp} alt="WhatsApp" />
    </S.FloatIcon>
  </Template>;
}

export default FaleConosco;
