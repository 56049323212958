import styled, { css } from 'styled-components'

export const Container = styled.div.attrs(() => {
  return {
    className: 'post-options'
  }
})`
  .post-option-text {
    margin-bottom: 0;
  }
`

export interface ReactionOptionsProps {
  open: boolean
}

export const ReactionOptions = styled.div.attrs(() => {
  return {
    className: 'reaction-options reaction-options-dropdown'
  }
}) <ReactionOptionsProps>`
  position: absolute;
  z-index: 9999;
  bottom: 54px;
  left: -16px;
  transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;

  ${props => props.open
    ? css`
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
  `
    : css`
      opacity: 0;
      visibility: hidden;
      transform: translate(0px, 20px);
    `}
`
