import { useCallback, useEffect, useState } from 'react';
import InputSelect from '../../../../components/Forms/InputSelect';
import api from '../../../../services/api';

export default function SeletorGA ({ gr, onChange }) {
  const [ga, setGa] = useState();
  const loadGa = useCallback(async (params) => {
    console.log(params);
    const response = await api.get('/ga', { params });
    setGa(response.data.ga);
  }, []);
  useEffect(() => {
    setGa();
    if (!gr) return;
    const params = { gr };
    loadGa(params);
  }, [loadGa, gr]);
  if (!ga) {
    return <InputSelect label="Selecione um GA" onChange={(evt) => onChange(evt)} large={true} disabled={true}>
      <option value="">Aguardando GR...</option>
    </InputSelect>;
  }
  return <InputSelect label="Selecione um GA" onChange={(evt) => onChange(evt)} large={true}>
    <option value="">Selecione um GA</option>
    {ga && ga.map((r, index) => <option key={index} value={r.name}>{r.name}</option>)}
  </InputSelect>;
}
