import { ComponentType, ReactElement, ReactNode, useCallback, useState } from 'react'
import Modal from 'react-modal'
import angry from '../../../assets/img/reaction/angry.png'
import dislike from '../../../assets/img/reaction/dislike.png'
import funny from '../../../assets/img/reaction/funny.png'
import happy from '../../../assets/img/reaction/happy.png'
import like from '../../../assets/img/reaction/like.png'
import love from '../../../assets/img/reaction/love.png'
import sad from '../../../assets/img/reaction/sad.png'
import wow from '../../../assets/img/reaction/wow.png'
import * as S2 from '../../../contexts/ModalContext/styled'
import { useClickOutside } from '../../../hooks/useClickOutside'
import FeedContentComponent from '../FeedContentComponent'
import { CardPostActionComponentProps } from './props'
import * as S from './style'

const customStyles: Modal.Styles = {
  content: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: ' translate(-50%, -50%)',
    backgroundColor: ' #fff',
    borderRadius: ' 8px',
    boxShadow: ' 0 2px 4px rgba(0, 0, 0, 0.2)',
    padding: '20px',
    overflow: 'auto',
    width: 'large'
  }
}

const CustomModal = Modal as ComponentType<ReactModal['props']>

export default function CardPostActionComponent ({ content }: CardPostActionComponentProps): ReactElement {
  const [open, setOpen] = useState<boolean>(false)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [wrapperRef] = useClickOutside(() => setOpen(false))
  const openReactionOverlay = useCallback((evt: any) => {
    evt.preventDefault()
    evt.stopPropagation()
    setOpen(true)
  }, [])
  const applyReaction = useCallback((evt: any) => {
    evt.preventDefault()
    evt.stopPropagation()
    setOpen(false)
  }, [])
  const [header, setHeader] = useState<string>('Header')
  const [body, setBody] = useState<ReactNode>(null)
  const openPost = useCallback(() => {
    setModalIsOpen(true)
    setHeader('Post')
    setBody(<FeedContentComponent content={content} />)
  }, [])
  const closeModal = useCallback(() => {
    setModalIsOpen(false)
  }, [])
  return <S.Container>
    <div className="post-option-wrap" style={{ position: 'relative' }} onClick={openReactionOverlay}>
      <div className="post-option reaction-options-dropdown-trigger">
        <svg className="post-option-icon icon-thumbs-up">
          <use xlinkHref="#svg-thumbs-up"></use>
        </svg>

        <p className="post-option-text">Reagir!</p>
      </div>

      <S.ReactionOptions open={open} ref={wrapperRef}>
        <div className="reaction-option text-tooltip-tft" data-title="Like" style={{ position: 'relative' }} onClick={applyReaction}>
          <img className="reaction-option-image" src={like} alt="reaction-like" />
          <div className="xm-tooltip" style={{ whiteSpace: 'nowrap', position: 'absolute', zIndex: '99999', top: '-28px', left: '50%', marginLeft: '-24px', opacity: '0', visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s' }}><p className="xm-tooltip-text">Like</p></div>
        </div>

        <div className="reaction-option text-tooltip-tft" data-title="Love" style={{ position: 'relative' }} onClick={applyReaction}>
          <img className="reaction-option-image" src={love} alt="reaction-love" />
          <div className="xm-tooltip" style={{ whiteSpace: 'nowrap', position: 'absolute', zIndex: '99999', top: '-28px', left: '50%', marginLeft: '-24px', opacity: '0', visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s' }}><p className="xm-tooltip-text">Love</p></div>
        </div>

        <div className="reaction-option text-tooltip-tft" data-title="Dislike" style={{ position: 'relative' }} onClick={applyReaction}>
          <img className="reaction-option-image" src={dislike} alt="reaction-dislike" />
          <div className="xm-tooltip" style={{ whiteSpace: 'nowrap', position: 'absolute', zIndex: '99999', top: '-28px', left: '50%', marginLeft: '-24px', opacity: '0', visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s' }}><p className="xm-tooltip-text">Dislike</p></div>
        </div>

        <div className="reaction-option text-tooltip-tft" data-title="Happy" style={{ position: 'relative' }} onClick={applyReaction}>
          <img className="reaction-option-image" src={happy} alt="reaction-happy" />
          <div className="xm-tooltip" style={{ whiteSpace: 'nowrap', position: 'absolute', zIndex: '99999', top: '-28px', left: '50%', marginLeft: '-24px', opacity: '0', visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s' }}><p className="xm-tooltip-text">Happy</p></div>
        </div>

        <div className="reaction-option text-tooltip-tft" data-title="Funny" style={{ position: 'relative' }} onClick={applyReaction}>
          <img className="reaction-option-image" src={funny} alt="reaction-funny" />
          <div className="xm-tooltip" style={{ whiteSpace: 'nowrap', position: 'absolute', zIndex: '99999', top: '-28px', left: '50%', marginLeft: '-24px', opacity: '0', visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s' }}><p className="xm-tooltip-text">Funny</p></div>
        </div>

        <div className="reaction-option text-tooltip-tft" data-title="Wow" style={{ position: 'relative' }} onClick={applyReaction}>
          <img className="reaction-option-image" src={wow} alt="reaction-wow" />
          <div className="xm-tooltip" style={{ whiteSpace: 'nowrap', position: 'absolute', zIndex: '99999', top: '-28px', left: '50%', marginLeft: '-24px', opacity: '0', visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s' }}><p className="xm-tooltip-text">Wow</p></div>
        </div>

        <div className="reaction-option text-tooltip-tft" data-title="Angry" style={{ position: 'relative' }} onClick={applyReaction}>
          <img className="reaction-option-image" src={angry} alt="reaction-angry" />
          <div className="xm-tooltip" style={{ whiteSpace: 'nowrap', position: 'absolute', zIndex: '99999', top: '-28px', left: '50%', marginLeft: '-24px', opacity: '0', visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s' }}><p className="xm-tooltip-text">Angry</p></div>
        </div>

        <div className="reaction-option text-tooltip-tft" data-title="Sad" style={{ position: 'relative' }} onClick={applyReaction}>
          <img className="reaction-option-image" src={sad} alt="reaction-sad" />
          <div className="xm-tooltip" style={{ whiteSpace: 'nowrap', position: 'absolute', zIndex: '99999', top: '-28px', left: '50%', marginLeft: '-24px', opacity: '0', visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s' }}><p className="xm-tooltip-text">Sad</p></div>
        </div>
      </S.ReactionOptions>
    </div >

    <div className="post-option" onClick={openPost}>
      <svg className="post-option-icon icon-comment">
        <use xlinkHref="#svg-comment"></use>
      </svg>

      <p className="post-option-text">Comentar</p>
    </div>

    <CustomModal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={customStyles}
    >
      <S2.Header>{header}</S2.Header>
      <hr />
      <S2.Body>{body}</S2.Body>
      <S2.ButtonClose onClick={() => closeModal()}>X</S2.ButtonClose>
    </CustomModal>
  </S.Container>
}
