import FooterComponent from '../components/FooterComponent';
import HeaderComponent from '../components/Header';
import PageLoaderComponent from '../components/PageLoader';
import SidebarComponent from '../components/SidebarComponent';
import { useAuth } from '../contexts/AuthContext';
import { useMenu } from '../contexts/DesktopMenuContext';
import * as S from './styled';

export default function Template ({ children }) {
    const { avatar, user } = useAuth();
    const { open } = useMenu();
    return user ? (
        <>
            <SidebarComponent />
            <HeaderComponent />
            <S.Container className={`content-grid ${open ? '' : 'with-menu-desktop'}`}>
                {children}
            </S.Container>
            <FooterComponent />
        </>
    ) : <PageLoaderComponent />;
}
