import styled from 'styled-components'

export const SpeciesComponentTabs = styled.div`
  width: 100%;
  border-radius: 4px;
  margin-top: 28px;
  margin-bottom: 28px;
`

export const SpeciesOptions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
`

export const SpeciesOption = styled.div<{ $isActive: boolean, $isSelected: boolean }>`
  padding: 0.5rem 1rem;
  color: var(--primary);
  background-color: ${({ $isActive }) => ($isActive ? '#EBF3EF !important' : 'var(--white)')};
  background-color: ${({ $isSelected }) => ($isSelected ? '#1DF161' : '')};
  border: ${({ $isActive, $isSelected }) => ($isActive || $isSelected ? '1px solid var(--primary)' : 'none')};
  border-radius: 6px 6px 0 0;
  border-bottom: ${({ $isActive, $isSelected }) => ($isActive || $isSelected ? '1px solid #EBF3EF' : 'none')};
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`

export const SpeciesContent = styled.div`
  background-color: #EBF3EF;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 6px 6px;
`

export const SpeciesOptionContent = styled.div<{ $isActive: boolean }>`
  width: 100%;
  display: ${({ $isActive }) => ($isActive ? 'flex' : 'none')};
  flex-direction: column;
  padding: 12px;

  .switch-container {
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    p {
      margin: 0;
      margin-right: 20px;
    }
  }
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`
export const SpeciesSwitch = styled.div`
    position: relative;
    width: 52px;
    min-width: 52px;
    height: 28px;
    overflow: hidden;
    border: 1px solid #88A8A4;
    border-radius: 200px;

    input {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 3;

        &:checked + .switch-knob:before {
            content: "";
            left: 26px;
        }
        &:checked ~ .switch-layer {
            background-color: #1DF161;
        }
    }

    .switch-knob {
        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 2px;
            left: 2px;
            width: 22px;
            height: 22px;
            background-color: var(--primary);
            border-radius: 50%;
            transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
        }
    }

    .switch-layer {
        border-radius: inherit;
        transition: all 0.3s ease-in-out;
        width: 100%;
        background-color: var(--white);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 28px;
    }
`
