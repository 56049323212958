import styled from 'styled-components'

export const Container = styled.div.attrs(() => {
  return {
    className: 'content-actions'
  }
})`
  .meta-line-text {
    margin-bottom: 0;
  }
`
