import styled, { keyframes } from 'styled-components'

const fadeEaseInOut = keyframes`
 0% {
    transform: translate(50%);
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    position: absolute;
    left: 0px;
 }
 100% {
    opacity: 1;
    transform: translateX(0px);
    visibility: visible;
    z-index: 1;
    position: absolute;
    left: 0px;
 }
`

export const ContainerLogin = styled.div`
  @media (min-width: 1365px) {
    animation-name: ${fadeEaseInOut};
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
  }
  @media (max-width: 1365px) {
    &&& .form-box-decoration {
      display: none;
    }
  }
  img.right {
    position: absolute;
    left: 360px;
    top: -70px;
    max-width: 160px;
  }
  img.left {
    position: absolute;
    left: -60px;
    top: -80px;
    max-width: 140px;
  }
  p.form-text {
    text-align: center;
  }
  button.secondary {
    background-color: var(--primary);
  }
  .tab-switch {
    position: absolute;
    max-width: 60%;
    .false {
      color: #888;
      background-color: #1DF16130;
    }
    .active {
      color: #fff;
      background-color: #1DF161;
    }
  }
  & > img.logo-home {
    display: none;
  }
  @media (max-width: 1365px) {
    & > img.logo-home {
      display: block;
      max-width: 120px;
      position: absolute;
      left: 50%;
      margin-left: -60px;
      margin-top: -60px;
    }
  }
`

export const ContainerRegister = styled.div`
  height: auto;
  img.right {
    position: absolute;
    left: 360px;
    top: -25px;
    max-width: 160px;
  }
  img.left {
    position: absolute;
    left: -60px;
    top: -30px;
    max-width: 140px;
  }
  .tab-switch {
    position: absolute;
    max-width: 60%;
    margin-left: 16px;
    .false {
      color: #888;
      background-color: #4444ee30;
    }
    .active {
      color: #fff;
      background-color: #4444e0;
    }
  }

  h2 {
    padding-top: 60px;
    margin-bottom: 16px;
  }

  form {
    margin-top: 0 !important;
  }

  button.button.primary {
    background-color: var(--primary);
  }

  button.button.primary:hover {
    background-color: var(--button-primary-hover);
  }

  @media (min-width: 1366px) {
    height: 100%;
    max-height: 735px;

    animation-name: ${fadeEaseInOut};
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
  }
`

export const ContainerHolder = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  @media (min-width: 1366px) {
    width: 100%;
    height: calc(100% - 76px);
    overflow-y: auto;
    overflow-x: visible;

    .form {
      padding-right: 8px;

      .form-row {
        margin: 0;
      }
    }
  }
`

export const Line = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 16px;
`
export const Landing = styled.div`
  background-position: -10% center;
  .landing-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    .vikinger-logo {
      max-width: 42px;
      width: 100%;
    }
    .info-title,
    .info-description {
      color: #fff;
      text-align: center;
    }
    .info-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 56px;
      margin-bottom: 0;
      margin-top: 16px;
      /* text-transform: uppercase; */
      span {
        font-size: 104px;
        font-weight: 900;
        
        sup {
          font-size: 20px;
          vertical-align: super;

          @media (max-width: 500px) {
          font-size: 10px;
        }
        }

        @media (max-width: 500px) {
          font-size: 60px;
        }
      }
    }
    .info-description {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.33333em;
      margin-bottom: 0;
      margin-top: 30px;
      max-width: 384px;
    }
  }
  .landing-form {
    margin-top: 80px;
    right: 80px;
  }
  @media (min-width: 1440px) {
    background-size: cover;
  }
  @media (max-width: 1365px) {
    .landing {
      padding: 80px 0 100px;
      position: static;
    }
    .landing-info {
      .info-description {
        display: none;
      }
    }
    .form-box {
      position: unset;
      top: unset;
      right: unset;
    }
  }
  @media (max-width: 1280px) {
    &.landing {
      height: unset;
    }
  }
  @media (max-width: 500px) {
    .form-box {
      padding-top: 64px;
    }
  }
`
export const ContainerButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-inline: 20px;
  width: 100%;
  z-index: 10;
  .filled {
    background-color: var(--white);
    color: #3e3f5e;
  }
  .login-btn {
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
  }
  .register-btn {
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
  }
  `
export const ButtonNavigation = styled.button`
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  color: var(--white);
  cursor: pointer;
  font-family: Gilroy-Medium,sans-serif;
  font-weight: 700;
  height: 54px;
  justify-content: center;
  margin-top: 90px;
  transition: 0.3s ease;
  max-width: 180px;
  width: 100%;
  :focus {
    outline: none;
  }
`
