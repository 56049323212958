import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import NotFound from './pages/404';
import AddNewUser from './pages/AddNewUser';
import AdminHome from './pages/Admin';
import AdminBanner from './pages/Admin/Banner';
import AdminBlog from './pages/Admin/Blog';
import NewPost from './pages/Admin/Blog/NewPost';
import Dashboard from './pages/Admin/Dashboard';
import NotApproved from './pages/Admin/NotApprovedUsers';
import AdminQuiz from './pages/Admin/Quiz';
import RankingSelector from './pages/Admin/RankingSelector';
import AdminReports from './pages/Admin/Reports';
import AdminNotice from './pages/AdminNotice';
import AdminNoticeUser from './pages/AdminNoticeUser';
import AdminRoom from './pages/AdminRoom';
import AdminRoomNew from './pages/AdminRoom/New';
import AdminUpload from './pages/AdminUpload';
import Badges from './pages/Badges';
import Blog from './pages/Blog';
import Category from './pages/Category';
import ClientCallback from './pages/ClientCallback';
import Downloads from './pages/Downloads';
import EditUser from './pages/EditUser';
import FaleConosco from './pages/FaleConosco';
import Faq from './pages/Faq';
import Forums from './pages/Forums';
import Home from './pages/Home';
import Live from './pages/Live';
import Login from './pages/Login';
import Activation from './pages/Login/Activation';
import RecoveryPassword from './pages/Login/Recovery';
import Marketplace from './pages/Marketplace';
import Product from './pages/Marketplace/Product';
import Cart from './pages/Marketplace/Product/Cart';
import Checkout from './pages/Marketplace/Product/Cart/Checkout';
import MecanicasExtras from './pages/MecanicasExtras';
import Members from './pages/Members';
import Metas from './pages/Metas';
import Newsfeed from './pages/Newsfeed';
import Overview from './pages/Overview';
import Perfil from './pages/Perfil';
import AccountChangePassword from './pages/Perfil/tabs/Account/ChangePassword';
import AccountGeneralSettings from './pages/Perfil/tabs/Account/GeneralSettings';
import ProfileInfo from './pages/Perfil/tabs/Profile/ProfileInfo';
import ProfileMessages from './pages/Perfil/tabs/Profile/ProfileMessages';
import ProfileNotifications from './pages/Perfil/tabs/Profile/ProfileNotifications';
import ProfileBadges from './pages/Perfil/tabs/ProfileBadges';
import ProfileBlogPosts from './pages/Perfil/tabs/ProfileBlogPosts';
import ProfileEdit from './pages/Perfil/tabs/ProfileEdit';
import ProfileGroups from './pages/Perfil/tabs/ProfileGroups';
import ProfileMarketplace from './pages/Perfil/tabs/ProfileMarketplace';
import ProfileNewsfeed from './pages/Perfil/tabs/ProfileNewsfeed';
import ProfilePhotos from './pages/Perfil/tabs/ProfilePhotos';
import PhotoList from './pages/Perfil/tabs/ProfilePhotos/PhotoList';
import ProfileStore from './pages/Perfil/tabs/ProfileStore';
import StoreAccount from './pages/Perfil/tabs/Store/Account';
import StoreDownloads from './pages/Perfil/tabs/Store/Downloads';
import StoreManageItems from './pages/Perfil/tabs/Store/ManageItems';
import StoreSalesStatement from './pages/Perfil/tabs/Store/SalesStatement';
import PhotoToAccept from './pages/PhotoToAccept';
import PhotoToApprove from './pages/PhotoToApprove';
import Providers from './pages/Providers';
import EditProvider from './pages/Providers/EditProvider';
import NewProvider from './pages/Providers/NewProvider';
import Quests from './pages/Quests';
import Quiz from './pages/Quiz';
import QuizInterno from './pages/QuizInterno';
import QuizTotal from './pages/QuizTotal';
import Ranking from './pages/Ranking';
import Ranking2 from './pages/Ranking2';
import RankingDist from './pages/RankingDist';
import RankingHomolog from './pages/RankingHomolog';
import RankingQuizAtivo from './pages/RankingQuiz';
import RankingStaff from './pages/RankingStaff';
import RankingStaffHolding from './pages/RankingStaffHolding';
import Regulamento from './pages/Regulamento';
import RegulamentoEdit from './pages/Regulamento/RegulamentoEdit';
import Resultados from './pages/Resultados';
import StatusUsuarios from './pages/StatusUsuarios';
import Treinamentos from './pages/Treinamentos';
import Days100 from './pages/Treinamentos/100days';
import AprendaAVender from './pages/Treinamentos/AprendaAVender';
import ConstrutorDeVendas from './pages/Treinamentos/ConstrutorDeVendas';
import Digital2021 from './pages/Treinamentos/Digital2021';
import UserTerms from './pages/UserTerms';
import Usuarios from './pages/Usuarios';

function isAuthenticated () {
  const token = localStorage.getItem('token');
  if (!token) return false;
  return true;
}

function PrivateRoute ({ component: Component, permission, ...rest }) {
  const { userCan } = useAuth();
  const isNotAuthenticated = !isAuthenticated();
  const canNotAccess = !userCan(permission);

  if (isNotAuthenticated) return <Navigate to="/" />
  if (!permission) return <Component {...rest} />;
  if (canNotAccess) return <Navigate to="/home" />

  return <Component {...rest} />
}

export default function Rotas () {
  return (
    <Routes>
      <Route element={<Login />} path="/" />
      <Route element={<Activation />} path="activation/:token" />
      <Route element={<RecoveryPassword />} path="/recovery-password/:token" />
      <Route element={<ClientCallback />} path="/:clientName/callback" />
      <Route element={<PrivateRoute component={Quiz} permission="read:quiz" />} path="/quiz" />
      <Route element={<PrivateRoute component={QuizTotal} permission="read:quiz" />} path="/quiz/total/:quizId" />
      <Route element={<PrivateRoute component={QuizInterno} permission="read:quiz" />} exact path="/quiz/:quizId" />
      <Route element={<PrivateRoute component={RankingQuizAtivo} permission="read:ranking-quiz" />} path="/ranking-quiz" />
      <Route element={<PrivateRoute component={Home} />} path="/home" />
      <Route element={<PrivateRoute component={Newsfeed} />} path="/newsfeed" />
      <Route element={<PrivateRoute component={Forums} />} path="/forums" />
      <Route element={<PrivateRoute component={Overview} />} path="/overview" />
      <Route element={<PrivateRoute component={Members} permission="users:read" />} path="/members" />
      <Route element={<PrivateRoute component={Badges} permission="read:badges" />} path="/badges" />
      <Route element={<PrivateRoute component={Quests} permission="read:quest" />} path="/quests" />
      <Route element={<PrivateRoute component={Resultados} permission="read:result" />} path="/resultados" />
      <Route element={<PrivateRoute component={Resultados} permission="read:result" />} path="/resultados/:userId" />
      <Route element={<PrivateRoute component={Perfil} />} path="/perfil" >
        <Route index element={<PrivateRoute component={ProfileInfo} />} />
        <Route element={<PrivateRoute component={ProfileNotifications} />} path="notifications" />
        <Route element={<PrivateRoute component={ProfileMessages} />} path="messages" />
        <Route path="account">
          <Route element={<PrivateRoute component={AccountChangePassword} />} path="password" />
          <Route element={<PrivateRoute component={AccountGeneralSettings} />} path="settings" />
        </Route>
        <Route path="store">
          <Route element={<PrivateRoute component={StoreAccount} />} path="account" />
          <Route element={<PrivateRoute component={StoreManageItems} />} path="items" />
          <Route element={<PrivateRoute component={StoreSalesStatement} />} path="sales" />
          <Route element={<PrivateRoute component={StoreDownloads} />} path="downloads" />
        </Route>
        <Route element={<PrivateRoute component={ProfileNewsfeed} />} path="newsfeed" />
        <Route element={<PrivateRoute component={ProfileGroups} />} path="groups" />
        <Route element={<PrivateRoute component={ProfilePhotos} />} path="photos/" />
        <Route element={<PrivateRoute component={PhotoList} />} path="photos/:providerId" />
        <Route element={<PrivateRoute component={ProfileMarketplace} />} path="marketplace" />
        <Route element={<PrivateRoute component={ProfileBlogPosts} />} path="blog-posts" />
        <Route element={<PrivateRoute component={ProfileBadges} />} path="badges" />
        <Route element={<PrivateRoute component={ProfileStore} />} path="store" />
        <Route element={<PrivateRoute component={ProfileEdit} />} path="edit" />
      </Route>
      <Route element={<PrivateRoute component={Regulamento} permission="read:mecanicas" />} path="/mecanicas" />
      <Route element={<PrivateRoute component={FaleConosco} />} path="/fale-conosco" />
      <Route element={<PrivateRoute component={Providers} permission="all-providers:read" />} path="/providers" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={NewProvider} permission="providers:create" />} path="/providers/new" />
      <Route element={<PrivateRoute component={EditProvider} permission="providers:update" />} path="/providers/:providerId" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={Faq} />} path="/faq" />
      <Route element={<PrivateRoute component={Regulamento} />} path="/regulamento" />
      <Route element={<PrivateRoute component={RegulamentoEdit} />} path="/regulamento/edit" />
      <Route element={<PrivateRoute component={UserTerms} />} path="/terms" />
      <Route element={<PrivateRoute component={Cart} />} path="/cart" />
      <Route element={<PrivateRoute component={Checkout} />} path="/checkout" />
      <Route element={<PrivateRoute component={Treinamentos} />} path="/treinamentos" />
      <Route element={<PrivateRoute component={Days100} />} path="/treinamentos/100-days" />
      <Route element={<PrivateRoute component={AprendaAVender} />} path="/treinamentos/aprenda-a-vender" />
      <Route element={<PrivateRoute component={Digital2021} />} path="/treinamentos/digital-2021" />
      <Route element={<PrivateRoute component={ConstrutorDeVendas} />} path="/treinamentos/construtor-de-vendas" />
      <Route element={<PrivateRoute component={Marketplace} permission="read:troca-pontos" />} path="/marketplace" />
      <Route element={<PrivateRoute component={Category} permission="read:troca-pontos" />} path="/marketplace-category" />
      <Route element={<PrivateRoute component={Category} permission="read:troca-pontos" />} path="/marketplace-category/:categoryId" />
      <Route element={<PrivateRoute component={Product} />} path="/product/:cod" />
      <Route element={<PrivateRoute component={Downloads} />} path="/downloads" />
      <Route element={<PrivateRoute component={Ranking} permission="read:ranking" />} path="/ranking" />
      <Route element={<PrivateRoute component={Ranking2} />} path="/ranking2" />
      <Route element={<PrivateRoute component={RankingHomolog} permission="read:ranking" />} path="/ranking/:worksheetId" />
      <Route element={<PrivateRoute component={RankingSelector} permission="homolog-ranking:read" />} path="/ranking/homolog" />
      <Route element={<PrivateRoute component={RankingDist} permission="read:ranking" />} path="/dist/ranking" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={Metas} />} path="/metas" />
      <Route element={<PrivateRoute component={Blog} permission="blog:read" />} path="/blog/:slug" />
      <Route element={<PrivateRoute component={AdminHome} permission="dashboard:read" />} exact path="/admin" />
      <Route element={<PrivateRoute component={AdminRoom} permission="dashboard:read" />} exact path="/admin/room" />
      <Route element={<PrivateRoute component={AdminRoomNew} permission="dashboard:read" />} exact path="/admin/room/new" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={AdminBanner} permission="dashboard:read" />} exact path="/admin/banner" /> {/* Check permission value. DB: banner:update? */}
      <Route element={<PrivateRoute component={AdminNotice} permission="newsfeed:create" />} exact path="/admin/notice" />
      <Route element={<PrivateRoute component={Usuarios} permission="users:update" />} exact path="/admin/usuarios" />
      <Route element={<PrivateRoute component={AddNewUser} permission="users:create" />} path="/admin/usuarios/novo" />
      <Route element={<PrivateRoute component={EditUser} permission="users:update" />} path="/admin/usuarios/:userId" />
      <Route element={<PrivateRoute component={AdminUpload} permission="dashboard:read" type="results" />} path="/admin/results" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={AdminNoticeUser} permission="newsfeed:create" />} path="/admin/notice/:userId" />
      <Route element={<PrivateRoute component={NotApproved} permission="users:approve" />} path="/admin/not-approved/" />
      <Route element={<PrivateRoute component={Dashboard} permission="dashboard:read" />} path="/admin/dashboard" /> {/* Check permission value. dashboard:read? */}
      <Route element={<PrivateRoute component={AdminReports} permission="dashboard:read" />} path="/admin/reports/" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={PhotoToApprove} permission="photo:approve" />} path="/admin/photo-approve" />
      <Route element={<PrivateRoute component={PhotoToAccept} permission="photo:accept" />} path="/admin/photo-accept/" />
      <Route element={<PrivateRoute component={RankingStaff} permission="ranking-all:read" />} path="/admin/ranking" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={RankingStaffHolding} permission="ranking-all:read" />} path="/admin/ranking/:holdingId" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={AdminQuiz} permission="read:quiz" />} path="/admin/quiz" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={AdminQuiz} permission="read:quiz" />} path="/admin/quiz/:quizId" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={AdminBlog} permission="dashboard:read" />} path="/admin/blog" /> {/* Check permission value */}
      <Route element={<PrivateRoute component={AdminBlog} permission="blog:read" />} path="/blog" />
      <Route element={<PrivateRoute component={NewPost} permission="dashboard:read" />} path="/admin/blog/post" />
      <Route element={<PrivateRoute component={StatusUsuarios} permission="user-status:read" />} path="/admin/user-status/" />
      <Route element={<PrivateRoute component={MecanicasExtras} permission="read:mecanicas" />} path="/mecanicas/:name" />
      <Route element={<PrivateRoute component={Live} permission="read:live" />} path="/live/:liveId" />

      <Route element={<PrivateRoute component={NotFound} />} path="*" />
    </Routes>
  );
}