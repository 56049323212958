import InputSelect from '../Forms/InputSelect';

function CargoComponent ({ ...rest }) {
  return <InputSelect {...rest} name="cargo" label="Cargo">
    <option value="">Selecione o cargo</option>
    <option selected={rest.value === 'ATC'} value="ATC">ATC</option>
    <option selected={rest.value === 'ATC 2'} value="ATC2">ATC 2</option>
    <option selected={rest.value === 'Consultor 1'} value="Consultor 1">Consultor 1</option>
    <option selected={rest.value === 'Consultor 2'} value="Consultor 2">Consultor 2</option>
    <option selected={rest.value === 'Consultor 3'} value="Consultor 3">Consultor 3</option>
    <option selected={rest.value === 'Consultor 4'} value="Consultor 4">Consultor 4</option>
    <option selected={rest.value === 'Coordenador'} value="Coordenador">Coordenador</option>
    <option selected={rest.value === 'Gerente Regional'} value="Gerente Regional">Gerente Regional</option>
  </InputSelect>;
}

export default CargoComponent;
