import styled from 'styled-components'

export const PaymentsContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 284px 1fr;

  > :first-child {
    grid-area: 1 / 2 / 3 / 3;
    min-height: 51rem;
  }

  @media screen and (max-width: 1124px) {
    grid-template-columns: 1fr;

    > :first-child {
      grid-area: 1;
    }
  }
`

export const SectionTitle = styled.p`
  font-weight: 700;
  line-height: 1rem;
  margin-bottom: 2.25rem;
`

export const ConfirmMethodButton = styled.button`
  background-color: #23d2e2;
  border-radius: 10px;
  box-shadow: 4px 7px 12px 0 rgba(35,210,226,.2);
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 3rem;
  margin-top: 2.5rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
`

export const PaymentsHistoryTable = styled.table`
  * {
    color: #3e3f5e;
    line-height: 1em;
  }

  thead {
    border-bottom: 1px solid #eaeaf5;
    height: 2.5rem;

    tr {
      font-size: 0.75rem;
      text-transform: uppercase;

      th {
        font-weight: 700;

        :not(:first-child) {
          padding-inline: 1.5rem;
          text-align: center;
        }

        :last-child {
          padding-inline: 1.5rem 0;
        }
      }
    }
  }

  tbody {
    font-size: 0.875rem;

    tr {
      height: 3.438rem;

      td {
        text-align: center;

        :first-child {
          text-align: start;
        }

        :first-child, &.pending-payment {
          color: #8f91ac;
          font-weight: 500;
          line-height: 1.1428571429em;
        }

        :not(:first-child, .pending-payment) {
          font-weight: 700;
          padding-inline: 1.5rem;
        }

        :last-child {
          padding-inline: 1.5rem 0;
          text-align: end;

          :not(.pending-payment) {
            color: #1DF161;
          }
        }
      }

      :first-child {
        td {
          padding-top: 0.938rem;
        }
      }
    }

  }
`

export const MethodConfirmationContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  text-align: center;
`

export const Icon = styled.div`
  align-items: center;
  background-color: #23d2e2;
  border-radius: 50%;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 3rem;

  svg {
    fill: var(--white);
    height: 1rem;
    width: 1.25rem;
  }
`

export const StatusContainer = styled.div`
  display: grid;
  gap: 0.25rem;
  line-height: 1rem;

  > p {
    margin: 0;

    :first-child {
      font-weight: 700;
    }

    :last-child {
      color: #8f91ac;
      font-weight: 500;
    }
  }
`

export const ConfirmationDescription = styled.p`
  color: #8f91ac;
  line-height: 1.4285714286em;
  margin: 1.75rem 0 0;
`
