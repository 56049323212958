import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import CategoryCardProps from './CategoryCard.props'

export const CategoryCardContainer = styled(Link)<Pick<CategoryCardProps, 'bgImgSrc'>>`
  ${({ bgImgSrc }) => css`
    background-size: cover;
    border-radius: 12px;
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    height: 142px;
    padding: 26px 28px;

    p {
      color: var(--white);
      font-weight: 700;
      margin: 0;
    }

    &:nth-child(1) {
      background: url(${bgImgSrc}) no-repeat right top, linear-gradient(to right, var(--primary), var(--secondary));
      color: var(--primary);
    }

    &:nth-child(2) {
      background: url(${bgImgSrc}) no-repeat right top, linear-gradient(to right, #417ae1, #5aafff);
      color: #4f8dff;
    }

    &:nth-child(3) {
      background: url(${bgImgSrc}) no-repeat right top, linear-gradient(to right, #2ebfef, #4ce4ff);
      color: #3ad2fe;
    }

    &:nth-child(4) {
      background: url(${bgImgSrc}) no-repeat right top, linear-gradient(to right, #17cada, #2becfe);
      color: var(--secondary);
    }
  `
}
`

export const CategoryCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  p {
    &:first-child {
      font-size: 1.125rem;
    }

    &:last-child {
    font-size: 0.875rem;
    font-weight: 500;
  }
  }
`

export const ItemCount = styled.p`
  background-color: white;
  border-radius: 12.5rem;
  line-height: 1.25rem;
  padding-inline: 0.5rem;
  text-transform: uppercase;
  width: fit-content;

    &:nth-child(1) {
      color: var(--primary);
    }

    &:nth-child(2) {
     color: #4f8dff;
    }

    &:nth-child(3) {
     color: #3ad2fe;
    }

    &:nth-child(4) {
     color: var(--secondary);
    }
`
