import { ReactElement } from 'react'
import HexagonComponent from '../../Hexagon'
import { FeedCommentComponentProps } from './props'

export default function FeedCommentComponent ({ content }: FeedCommentComponentProps): ReactElement {
  console.log(content)
  return <div className="post-comment">
    <a className="user-avatar small no-outline" href="profile-timeline.html">
      <div className="user-avatar-content">
        <HexagonComponent src={content.avatar} size={undefined} className={undefined} />
      </div>
    </a>

    <p className="post-comment-text">
      <a className="post-comment-text-author">{content.user.name}</a>
      {content.comment}
    </p>
  </div >
}
