import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useMenu } from '../../../contexts/DesktopMenuContext';
import { useClickOutside } from '../../../hooks/useClickOutside';
import MenuButton from './MenuButton';
import * as S from './style';

function MobileMenu () {
    const [openMenu, setOpenMenu] = useState(false);
    const [wrapperRef] = useClickOutside(function () { setOpenMenu(false); });
    const { avatar, user } = useAuth();
    const { menuItens } = useMenu();
    const navigate = useNavigate();

    function handleLogout () {
        localStorage.clear();
        navigate('/');
    }

    const toggleMenu = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenMenu(!openMenu);
    }, [openMenu]);

    return (
        <div className="header-actions">
            <S.MobileWrapper className="action-list" onClick={toggleMenu}>
                <div className="action-list-item-wrap">
                    <div className="action-list-item">
                        <div className="action-list-item-icon burger-icon inverted">
                            <div className="burger-icon-bar"></div>

                            <div className="burger-icon-bar"></div>

                            <div className="burger-icon-bar"></div>
                        </div>
                    </div>
                </div>
                <div >
                    <nav id="navigation-widget-mobile" className={`navigation-widget navigation-widget-mobile sidebar left ${openMenu ? '' : 'hidden'}`} >
                        <div className="simplebar-wrapper" style={{ margin: '0px 0px -40px' }}>

                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer"></div>
                            </div>

                            <div className="simplebar-mask" onClick={toggleMenu} ref={wrapperRef}>
                                <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                                    <div className="simplebar-content-wrapper">
                                        <div className="simplebar-content" style={{ padding: '0px 0px 40px' }}>

                                            <div className="navigation-widget-close-button" onClick={toggleMenu}>
                                                <svg className="navigation-widget-close-button-icon icon-back-arrow">
                                                    <use href="#svg-back-arrow"></use>
                                                </svg>
                                            </div>
                                            <ul className="menu">
                                                {menuItens && menuItens.map((m, index) => <MenuButton key={index} {...m} />)}
                                            </ul>

                                            <p className="navigation-widget-section-title">Meu perfil</p>

                                            <Link className="navigation-widget-section-link" to="/perfil">Perfil</Link>

                                            <Link className="navigation-widget-section-link" to="/perfil/newsfeed">Notificações</Link>

                                            <p className="navigation-widget-section-title">Conta</p>

                                            <Link className="navigation-widget-section-link" to="/perfil/edit">Configurações Gerais</Link>

                                            <p className="navigation-widget-section-title">Minha loja</p>

                                            <Link className="navigation-widget-section-link" to="/perfil">
                                                Minha Conta
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="simplebar-placeholder" style={{ width: 'auto', height: '270px' }}></div>
                        </div>
                        <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                            <div className="simplebar-scrollbar" style={{ height: '33px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }}></div>
                        </div>
                    </nav>
                </div>
            </S.MobileWrapper>
        </div>
    );
}

export default MobileMenu;
