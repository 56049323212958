import { isAxiosError } from 'axios';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import defaultAvatar from '../../assets/img/avatar.png';
import api from '../../services/api';

const AuthContext = createContext();

export default function AuthProvider ({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [refreshToken, setRefreshToken] = useState(null);
  const [acceptedAt, setAcceptedAt] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [code, setCode] = useState();
  const navigate = useNavigate();

  const userCan = useCallback(
    (permission, checkApproved) => {
      const notHasAccept = localStorage.getItem('acceptedAt') === "null"
      if (notHasAccept && !checkApproved) {
        const user = JSON.parse(localStorage.getItem('user'));
        navigate(`/activation/${user.activationToken}`);
        return false
      }
      if (!permissions.length) {
        const localPermissions = JSON.parse(localStorage.getItem('permissions'));
        return localPermissions.includes(permission);
      }
      if (checkApproved && !user?.approved) return false;
      return permissions.includes(permission);
    },
    [permissions, user]
  );

  const fetchPermissions = async () => {
    const response = await api.get('/permissions')
    return response.data
  }

  const fetchAccept = async () => {
    const response = await api.get('/accept')
    return response.data
  }

  const saveLogin = useCallback(
    async (response) => {
      const { data } = response

      localStorage.setItem('token', data.token);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('user', JSON.stringify(data.user));

      setToken(data.token);
      setRefreshToken(data.refreshToken);
      setUser(data.user);

      const permissions = await fetchPermissions()
      setPermissions(permissions);
      localStorage.setItem('permissions', JSON.stringify(permissions))

      const acceptedAt = await fetchAccept()
      localStorage.setItem('acceptedAt', acceptedAt)
      setAcceptedAt(acceptedAt);

      const avatar = await api.get('/avatar')
      setAvatar(defaultAvatar)
      if (avatar.data) {
        setAvatar(avatar.data.path);
      }

      if (response.data.user.hasPassword === false) {
        return;
      }

      if (!acceptedAt) {
        navigate(`/activation/${data.user.activationToken}`);
        return response
      }
      if (data.token) {
        navigate('/home');
      }

      return response;
    },
    [navigate]
  );

  const loadCode = useCallback(async () => {
    try {
      const response = await api.get('/auth-at-2022');
      return setCode(response.data.token);
    } catch (error) { }
  }, []);

  const doLogin = useCallback(
    async (username, password) => {
      try {
        const response = await api.post('session', { username, password });
        loadCode();
        return saveLogin(response);
      } catch (err) {
        if (!isAxiosError(err)) {
          toast.error('Ocorreu um erro interno no servidor', {
            theme: 'colored'
          });
          return;
        }
        toast.error("Credenciais inválidas", { theme: 'colored' });
      }
    },
    [saveLogin, loadCode]
  );

  useEffect(() => {
    const tokenData = localStorage.getItem('token');
    if (!tokenData) return;

    const userData = JSON.parse(localStorage.getItem('user'));
    const acceptedAt = localStorage.getItem('acceptedAt');
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    setToken(tokenData);
    setUser(userData);
    setAcceptedAt(acceptedAt);
    setPermissions(permissions);
    api.get('/avatar').then(avatar => {
      setAvatar(defaultAvatar)
      if (avatar.data) {
        setAvatar(avatar.data.path);
      }
    })
  }, []);

  useEffect(() => {
    loadCode();
  }, [loadCode]);

  const doLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('catalogUrl');
    localStorage.removeItem('permissions');
    setToken(null);
    setUser(null);
    setAcceptedAt(null);
    setPermissions(null);

    window.location = '/';
  }, []);

  useEffect(() => {
    if (!token) return;
    const timeout = setTimeout(async () => {
      try {
        const response = await api.post('/session/refresh', null, {
          headers: {
            Authorization: `Bearer ${refreshToken}`
          }
        });
        const { token: newToken, refreshToken: newRefreshToken } =
          response.data;
        localStorage.setItem('token', newToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        setToken(newToken);
        setRefreshToken(newRefreshToken);
      } catch (error) {
        toast.error('O token expirou', { theme: 'colored' });
        doLogout();
      }
    }, 30 * 60 * 1000);

    return () => clearTimeout(timeout);
  }, [token, refreshToken, doLogout]);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        acceptedAt,
        code,
        avatar,
        setAvatar,
        setUser,
        doLogin,
        doLogout,
        userCan,
        saveLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth () {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within a AuthProvider');

  return context;
}