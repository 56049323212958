import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import InputTextWithButton from '../../../components/Forms/InputTextWithButton'
import LinkButton from '../../../components/Forms/LinkButton'
import PageLoaderComponent from '../../../components/PageLoader'
import PaginationComponent from '../../../components/Pagination'
import { useAuth } from '../../../contexts/AuthContext'
import api from '../../../services/api'
import Template from '../../../template'
import * as S from './style'

export default function NotApprovedUsers () {
  const [users, setUsers] = useState();
  const [filter, setFilter] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const { doLogout } = useAuth();

  const loadUsers = useCallback(async (page) => {
    setLoading(true);
    try {
      const response = await api.get(`/user/not-approved?page=${page}&filter=${filter}`);
      setUsers(response.data.content);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.page);
    } catch (err) {
      if (!err.response.data.message) {
        toast.error('Ocorreu um erro, por favor tente novamente mais tarde', { theme: 'colored' });
        return;
      }

      if (err.response.data.message === 'Token inválido') {
        doLogout();
        toast.error(err.response.data.message, { theme: 'colored' });
        return;
      }

      toast.error(err.response.data.message, { theme: 'colored' });
    }
    setLoading(false);
  }, [doLogout, filter]);

  const handleFilter = useCallback(async (newFilter) => {
    setFilter(newFilter || '');
    loadUsers(1);
  }, [loadUsers]);

  useEffect(() => {
    loadUsers(1);
  }, [loadUsers]);

  if (loading) return <PageLoaderComponent />;

  return (
    <Template>

      <S.Tabela>
        <S.Wrapper className="grid mobile-prefer-content">
          <S.HeaderWrapper>
            <InputTextWithButton label="Nome/Email/Documento" onClick={(value) => handleFilter(value)} />

            <LinkButton to="/admin/usuarios/novo" >Adicionar novo</LinkButton>
          </S.HeaderWrapper>
          <div className="grid-column">
            <table>
              <tr>
                <th>Nome</th>
                <th>CNPJ</th>
                <th>Regional</th>
                <th>&nbsp;</th>
              </tr>
              {users.map(user => <tr>
                <td>{user.name}</td>
                <td>{user.document}</td>
                <td>Sem regional</td>
                <S.ActionContainer>
                  <S.Edit to={`/admin/usuarios/${user.id}`}>
                    <i className="fas fa-edit"></i>
                  </S.Edit>
                </S.ActionContainer>
              </tr>)}
            </table>
          </div>
        </S.Wrapper>
      </S.Tabela>

      <PaginationComponent totalPages={totalPages} currentPage={currentPage} onChagePage={(page) => loadUsers(page)} />

    </Template >
  );
}
