import styled from "styled-components"

export const Figure = styled.figure`
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    height: auto;
    max-height: 100%;
    max-width: auto;
    width: auto;
  }
`