import { ReactElement, useCallback, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import InputText from '../../Forms/InputText'
import HexagonComponent from '../../Hexagon'
import { CommentFormComponentProps } from './props'

export default function CommentFormComponent ({ onPostComment }: CommentFormComponentProps): ReactElement {
  const { avatar, user } = useAuth()
  const [comment, setComment] = useState<string>('')
  const handleSubmit = useCallback((evt: any) => {
    evt.preventDefault()
    onPostComment({
      avatar,
      user,
      comment
    })
  }, [comment])
  return <div className="post-comment-form bordered-top">
    <div className="user-avatar small no-outline">
      <HexagonComponent src={avatar} size={undefined} className={undefined} />
    </div>

    <form className="form" onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="form-item">
          <InputText
            name="comment"
            id="comment"
            label="Comentário"
            value={comment}
            onChange={(value) => setComment(value)}
            type="text"
          />
        </div>
      </div>
    </form>
  </div>
}
