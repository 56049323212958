import DOMPurify from 'dompurify'
import { convertToHTML } from 'draft-convert'
import { ContentState, EditorState, convertFromHTML } from 'draft-js'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { toast } from 'react-toastify'
import api from '../../services/api'
import * as S from './style'

interface TextEditorProps {
  initialHTMLContent: string
  serverUrlToUpdate: string
}

function TextEditor ({ initialHTMLContent, serverUrlToUpdate }: TextEditorProps): ReactElement {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  const onEditorChange = useCallback((newEditorState: EditorState) => {
    setEditorState(newEditorState)
  }, [])

  const createMarkup = useCallback((html: string) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }, [])

  const handleUpdateContent = useCallback(() => {
    const html = convertToHTML(editorState.getCurrentContent())
    try {
      api.put(serverUrlToUpdate, {
        content: createMarkup(html).__html
      })
      toast.success('Conteúdo atualizado com sucesso!', {
        theme: 'colored'
      })
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao tentar atualizar o conteúdo. Por favor, tente novamente.', {
        theme: 'colored'
      })
    }
  }, [editorState])

  useEffect(() => {
    if (initialHTMLContent !== null) {
      const blocksFromHTML = convertFromHTML(initialHTMLContent)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      setEditorState(EditorState.createWithContent(state))
    }
  }, [initialHTMLContent])

  return <S.TextEditor>
    <Editor
    editorState={editorState}
    onEditorStateChange={onEditorChange}
    wrapperClassName="wrapper-class"
    editorClassName="editor-class"
    toolbarClassName="toolbar-class"
    toolbar={{
      options: ['inline', 'blockType', 'list', 'history'],
      inline: {
        options: ['bold', 'italic', 'underline']
      },
      blockType: {
        options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote']
      },
      list: {
        options: ['unordered', 'ordered']
      },
      history: {
        options: ['undo', 'redo']
      }
    }}
    />
    <S.ButtonWrapper>
      <S.Button onClick={handleUpdateContent}>Salvar</S.Button>
    </S.ButtonWrapper>
  </S.TextEditor>
}

export default TextEditor
