import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import * as S from '../style';

function MenuButton ({ path, name, permission }) {
  const location = useLocation();
  const { userCan } = useAuth();
  if (permission && !userCan(permission)) return '';
  return <S.Li className={`menu-item ${location.pathname.includes(path) ? 'active' : ''}`}>
    <Link className="menu-item-link" to={path}>
      <div className="menu-item-link-icon">
        <p>{name}</p>
      </div>
    </Link>
  </S.Li>;
}

export default MenuButton;
