import { AxiosError } from 'axios'
import { ReactElement, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import schema from '../../config/passwordFormScheme.json'
import { useCampaign } from '../../contexts/CampaignContext'
import { FormValidator } from '../../domain/FormValidator'
import api from '../../services/api'
import Checkbox from '../Forms/Checkbox'
import InputPassword from '../Forms/InputPassword'
import * as S from './style'

interface PasswordFormData {
  newPassword: string
  password: string
  passwordConfirmation: string
  acceptInfo: boolean
  acceptTerms: boolean
}

const formValidator = new FormValidator(schema)

interface UserUpdatePasswordFormProps {
  onSave: () => void
}

export function UserUpdatePasswordForm ({
  onSave
}: UserUpdatePasswordFormProps): ReactElement {
  const [password, setPassword] = useState<PasswordFormData>({
    newPassword: '',
    password: '',
    passwordConfirmation: '',
    acceptInfo: false,
    acceptTerms: false
  })
  const { campaignData } = useCampaign()
  const [errors, setErrors] = useState<any>()

  const hasLowercase = useCallback((value: string) => {
    if (value === '') return ''
    if (value.match(/[a-z]/) === null) return ''
    if (value.match(/[a-z]/)?.length === 0) return ''
    return 'active'
  }, [])

  const hasUppercase = useCallback((value: string) => {
    if (value === '') return ''
    if (value.match(/[A-Z]/) === null) return ''
    if (value.match(/[A-Z]/)?.length === 0) return ''
    return 'active'
  }, [])

  const hasNumber = useCallback((value: string) => {
    if (value === '') return ''
    if (value.match(/[0-9]/) === null) return ''
    if (value.match(/[0-9]/)?.length === 0) return ''
    return 'active'
  }, [])

  const hasSpecial = useCallback((value: string) => {
    if (value === '') return ''
    if (value.match(/[!@#$%&*]/) === null) return ''
    if (value.match(/[!@#$%&*]/)?.length === 0) return ''
    return 'active'
  }, [])

  const handleSave = useCallback(
    async (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault()
      if (!formValidator.validate(password)) {
        setErrors(formValidator.errors)
        return
      }
      try {
        await api.patch('/me/password', password)
        onSave()
      } catch (error: any) {
        const err = error as AxiosError
        if (err !== null && err?.response?.status !== 500) {
          toast.error(err?.response?.data.message, { theme: 'colored' })
          return
        }
        toast.error('Ocorreu um erro interno no servidor.', {
          theme: 'colored'
        })
      }
    },
    [onSave, password]
  )

  return (
    <form onSubmit={handleSave}>
      <S.Row>
        <InputPassword
          id="password"
          name="password"
          label="Senha"
          value={password.password !== null ? password.password : ''}
          onChange={(currentPassword) =>
            setPassword({ ...password, password: currentPassword })
          }
          error={errors?.password}
        />
      </S.Row>
      <S.Row>
        <InputPassword
          id="new-password"
          name="newPassword"
          label="Nova Senha"
          value={password.newPassword !== null ? password.newPassword : ''}
          onChange={(newPassword) => setPassword({ ...password, newPassword })}
          error={errors?.newPassword}
        />
      </S.Row>
      <S.Row>
        <S.CheckContainer>
          <S.Check className={hasLowercase(password.newPassword)} /> Letra
          minúscula
        </S.CheckContainer>
        <S.CheckContainer>
          <S.Check className={hasUppercase(password.newPassword)} /> Letra
          maiúscula
        </S.CheckContainer>
        <S.CheckContainer>
          <S.Check className={hasNumber(password.newPassword)} /> Número
        </S.CheckContainer>
        <S.CheckContainer>
          <S.Check className={hasSpecial(password.newPassword)} /> Caractere
          especial
        </S.CheckContainer>
      </S.Row>
      <S.Row>
        <InputPassword
          id="password-confirmation"
          name="password_confirmation"
          label="Confirmação de senha"
          value={password.passwordConfirmation !== null ? password.passwordConfirmation : ''}
          onChange={(passwordConfirmation) =>
            setPassword({ ...password, passwordConfirmation })
          }
          error={errors?.passwordConfirmation}
        />
      </S.Row>
      <S.Row>
        <Checkbox
          name="check1"
          text={
            <>
              Aceito os termos do{' '}
              <a
                href={campaignData?.privacyLink}
                target="_blank"
                rel="noreferrer"
              >
                Aviso de Privacidade da {campaignData.companyName}
              </a>{' '}
              e da{' '}
              <a href="/politica-de-uso" target="_blank">
                Plataforma
              </a>
              .
            </>
          }
          onChange={(acceptTerms) => setPassword({ ...password, acceptTerms })}
          error={errors?.acceptTerms}
          checked={password.acceptTerms}
          label={''}
        />
      </S.Row>
      <S.Row>
        <Checkbox
          name="check2"
          text={`Aceito receber informações sobre produtos, novidades, ofertas promocionais e marcas da ${campaignData.companyName} por e-mail, telefone, Whatsapp e SMS.`}
          onChange={(acceptInfo) => setPassword({ ...password, acceptInfo })}
          checked={password.acceptInfo}
        />
      </S.Row>

      <button type="submit" className="button secondary">
        Salvar
      </button>
    </form>
  )
}
