import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DefaultAvatar from '../../assets/img/avatar.png';
import InputSelect from '../../components/Forms/InputSelect';
import HexagonComponent from '../../components/Hexagon';
import PageLoader from '../../components/PageLoader';
import useOnScreen from '../../hooks/useOnScreen';
import api from '../../services/api';
import Template from '../../template';
import * as S from './style';

function RankingQuiz () {
  const [ranking, setRanking] = useState();
  const [type] = useState('active');
  const [quizId, setQuizId] = useState();
  const ref = useRef(null);
  const onceTriggered = useRef(true);
  const isVisible = useOnScreen(ref, ranking);

  const loadData = useCallback(async (quizId) => {
    const response = await api.get('/quiz/ranking', { params: { quizId } });
    setRanking(response.data);
  }, []);

  useEffect(() => {
    loadData(quizId);
  }, [loadData, quizId]);

  useEffect(() => {
    if (isVisible && onceTriggered.current) {
      onceTriggered.current = false;
    }
  }, [isVisible, ranking]);

  const GraphicalStepsByPosition = useMemo(() => {
    if (!ranking) return <></>;
    if (!ranking[type]) return <S.Center>Ainda não temos ranking para esse quiz!</S.Center>;
    return ranking[type].map((rankingItem, index) => {
      if (index > 2) return null;
      const ParticipantInfo = <div className="user-preview-info">
        <div className="user-short-description">
          <div className="user-short-description-avatar user-avatar medium">
            <div className="user-avatar-border">
              <HexagonComponent src={rankingItem.avatar || DefaultAvatar} />
              <div className="label-position">
                {index + 1}
              </div>
            </div>
          </div>
          <div className="user-short-description-title">
            <p>{rankingItem.name.split(' ')[0]}</p>
            <p>{rankingItem.points}</p>
          </div>
        </div>
      </div>;
      if (index === 0) {
        return (
          <S.FirstPlace>
            {ParticipantInfo}
            <div className={`step1 ${(onceTriggered.current && isVisible) ? 'run-animation' : 'static'}`}>
              <p ref={ref}>1</p>
            </div>
          </S.FirstPlace>);
      }
      if (index === 1) {
        return (
          <S.SecondPlace>
            {ParticipantInfo}
            <div className={`step2 ${(onceTriggered.current && isVisible) ? 'run-animation' : 'static'}`}>
              <p>2</p>
            </div>
          </S.SecondPlace>
        );
      }
      return (
        <S.ThirdPlace>
          {ParticipantInfo}
          <div className={`step3 ${(onceTriggered.current && isVisible) ? 'run-animation' : 'static'}`}>
            <p>3</p>
          </div>
        </S.ThirdPlace>
      );
    });
  }, [isVisible, ranking, type]);

  if (!ranking) return <PageLoader />;

  return (
    <Template>
      <InputSelect onChange={(quizId) => setQuizId(quizId)}>
        {ranking.quizzes.map((q, index) => <option key={index} selected={ranking.active.id === quizId} value={q.id}>{q.title}</option>)}
      </InputSelect>
      <S.RankingQuizContainer>
        <div className="account-hub-content">
          {ranking[type].length === 0 && <S.Center>Ainda não temos ranking para esse quiz!</S.Center>}
          {ranking[type].length > 0 && <div className="grid-column">
            <S.RankingContainer>
              <S.GraphicalRankingContainer className='grid grid-3-3-3 centered'>
                {GraphicalStepsByPosition[1]}
                {GraphicalStepsByPosition[0]}
                {GraphicalStepsByPosition[2]}
              </S.GraphicalRankingContainer>
              <S.RankingList>
                {ranking[type].map((rankingItem, index) => {
                  if (index > 2) {
                    return (<S.RankingItem key={rankingItem.points + index}>
                      <div className="ranking-position">
                        {index + 1}
                      </div>
                      <div className="participant">
                        {rankingItem.name}
                      </div>
                      <div className="points">
                        {rankingItem.points}
                      </div>
                    </S.RankingItem>);
                  }
                  return null;
                })}
              </S.RankingList>
            </S.RankingContainer>
          </div>}
        </div>
      </S.RankingQuizContainer>
    </Template>);
}

export default RankingQuiz;
