import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import Card from '..'
import Banner from '../Banner'
import CardMarketplaceProps from './CardMarketplace.props'

import * as S from './style'

export default function CardMarketplace ({
  bannerImgSrc,
  category,
  description,
  itemCode,
  skus,
  title
}: CardMarketplaceProps): ReactElement {
  return <Card>
    <S.CardContainer>
      <Link to={`/product/${String(itemCode)}`}>
        <Banner>
          {skus[0].images[0] !== undefined && <img src={skus[0].images[0].path} alt={title} />}
        </Banner>
      </Link>

      <S.CardContentContainer>
        <S.PriceContainer>
          <p>
            <span>$</span>
            {' '}
            {Number(skus[0].price).toFixed(2).toString().replace(/\./g, ',')}
          </p>
        </S.PriceContainer>

        <S.TitleContainer>
          <Link to={`/product/${String(itemCode)}`}>{title}</Link>

          <S.CategoryLinkContainer>
            <Link to="">{category}</Link>
          </S.CategoryLinkContainer>
        </S.TitleContainer>

        <S.DescriptionContainer dangerouslySetInnerHTML={{ __html: description }} />
      </S.CardContentContainer>

    </S.CardContainer>
  </Card>
}
