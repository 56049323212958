import { ReactElement, useState } from 'react'
import Accordion from '../../components/Accordion'
import { useCampaign } from '../../contexts/CampaignContext'
import Template from '../../template'
import * as S from './style'

function Faq (): ReactElement {
  const [activeAccordion, setActiveAccordion] = useState(-1)
  const { campaignData } = useCampaign()

  const handleOnOpen = (newIndex: number): void => setActiveAccordion(newIndex)

  return <Template>
    <div style={{ marginTop: '8rem' }}></div>

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle"></p>
        <h2 className="section-title">{''}</h2>
      </div>
    </div>

    <S.WidgetBox className="widget-box">

      <p className="widget-box-title">
        Confira abaixo as perguntas frequentes e caso permaneça com alguma dúvida, entre em contato através de um chamado no campo ao final dela.
      </p>

      <div className="widget-box-content">
        <div className="simple-accordion-list">
          <Accordion
            index={0}
            question="1. Quem participa da Campanha?"
            isOpen={activeAccordion === 0}
            onOpen={handleOnOpen}
          >Vendas: Gerente de área e vendedores.<br />Merchan: gerentes, coordenadores e promotores (<strong>apenas</strong> funcionários MDLZ).</Accordion>

          <Accordion
            index={1}
            question="2. Como faço para saber a minha meta?"
            isOpen={activeAccordion === 1}
            onOpen={handleOnOpen}
          >Suas metas estarão disponíveis no site e no celular durante todo o período da campanha. Acesse o menu e clique no ícone “Mecânicas”.</Accordion>

          <Accordion
            index={2}
            question="3. Qual o período da campanha? "
            isOpen={activeAccordion === 2}
            onOpen={handleOnOpen}
          >A campanha terá duração de 01 de fevereiro a 31 de dezembro/2023.</Accordion>

          <Accordion
            index={3}
            question="4. Os pontos são acumulativos?"
            isOpen={activeAccordion === 3}
            onOpen={handleOnOpen}
          >Não. Você deverá realizar o resgate do seu prêmio em até 60 dias após a disponibilização do mesmo.</Accordion>

          <Accordion
            index={4}
            question="5. Como eu faço para acumular escudos mensalmente?"
            isOpen={activeAccordion === 4}
            onOpen={handleOnOpen}
          >Para acumular escudos, basta bater suas metas mensais. A quantidade de Escudos de cada meta será previamente informada na plataforma.</Accordion>

          <Accordion
            index={5}
            question="6. Caso eu esteja dentro do ranking de premiados, como faço para resgatar os prêmios?"
            isOpen={activeAccordion === 5}
            onOpen={handleOnOpen}
          >Acesse o link de resgate de prêmios, insira suas informações para receber no endereço e cadastrar na loja parceira/escolhida.</Accordion>

          <Accordion
            index={6}
            question="7. Caso o prêmio entregue esteja avariado, quem devemos acionar?"
            isOpen={activeAccordion === 6}
            onOpen={handleOnOpen}
          >Entre em contato com o SAC na plataforma na área fale conosco na plataforma {campaignData.email}</Accordion>

          <Accordion
            index={7}
            question="8. Quem acionar caso seja identificado erro no cálculo da Meta? "
            isOpen={activeAccordion === 7}
            onOpen={handleOnOpen}
          >Entre em contato com o SAC Gigantes na área fale conosco na plataforma no {campaignData.email}</Accordion>

          <Accordion
            index={8}
            question="9. Quais os KPI’s de cada onda?"
            isOpen={activeAccordion === 8}
            onOpen={handleOnOpen}
          >Verifique a mecânica completa e separada por canal dentro da plataforma.</Accordion>

          <Accordion
            index={9}
            question="10. Quais os produtos de cada onda?"
            isOpen={activeAccordion === 9}
            onOpen={handleOnOpen}
          >Verifique a mecânica completa e separada por canal dentro da plataforma.</Accordion>

          <Accordion
            index={10}
            question="11. Qual o período de duração de cada onda?"
            isOpen={activeAccordion === 10}
            onOpen={handleOnOpen}
          >1ª onda: fevereiro/março/abril<br />
            2ª onda: maio/junho<br />
            3ª onda: julho/agosto/setembro<br />
            4ª onda: outubro/novembro/dezembro
          </Accordion>

          <Accordion
            index={11}
            question="12. Qual o fluxo da campanha?"
            isOpen={activeAccordion === 11}
            onOpen={handleOnOpen}
          ><ul style={{ listStyleType: 'disc', paddingLeft: '36px' }}>
              <li>Execução</li>
              <li>Apuração – avaliação e cumprimento de metas.</li>
              <li>Divulgação - Realizada em 60 dias a contar do final da apuração*.<br />*Exceção a pontuação referente à Páscoa: divulgação será feita até 30 de setembro de 2023.</li>
              <li>Premiação - Realizada em 30 dias após a divulgação do resultado</li>
            </ul>
          </Accordion>

          <Accordion
            index={12}
            question="13. Como funciona a pontuação em caso de mudança de cargo ou posição do participante?"
            isOpen={activeAccordion === 12}
            onOpen={handleOnOpen}
          >
            Nesses casos os resultados já obtidos não poderão ser aproveitados para atingir suas novas metas. Se a alteração for dentro de algum cargo elegível de vendas será considerado o resultado do canal em que a permanência for maior.<br /><br />
            Ex: 60% GKA e 40% no LMT. <br />
            Resultado: Será considerado o GKA que você ficou 60% da onda.
          </Accordion>

          <Accordion
            index={13}
            question="14. Como fica a premiação caso do participante que for desligado?"
            isOpen={activeAccordion === 13}
            onOpen={handleOnOpen}
          >Em caso de desligamento, ele receberá a premiação de acordo com as metas que já tiver atingido até o momento do desligamento.</Accordion>

          <Accordion
            index={14}
            question="15. No caso de participantes afastados durante a campanha, como fica a pontuação?"
            isOpen={activeAccordion === 14}
            onOpen={handleOnOpen}
          ><ul style={{ listStyleType: 'decimal', paddingLeft: '16px' }}>
              <li>O Participante que permanecer afastado durante parte da duração da Campanha terá os pontos que obteve considerados proporcionalmente ao período efetivamente trabalhado.</li><br />
              <li>O Participante que permanecer afastado durante toda a duração da Campanha não será elegível ao Programa, pois não haverá prestação de serviços</li>
            </ul>
          </Accordion>
        </div>
      </div>
    </S.WidgetBox>
  </Template>
}

export default Faq
