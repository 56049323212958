import { ReactElement, useCallback, useEffect, useState } from 'react'
import api from '../../services/api'
import CategoryComponent from '../CategoryComponent'
import InputText from '../Forms/InputText'
import * as S from './style'

enum State {
  LOADING,
  READY
}

interface SpeciesComponentTabsProps {
  value: any[]
  onChange: (species: any) => void
  onChangeError: (error: boolean) => void
}

function SpeciesComponentTabs ({ value, onChange, onChangeError }: SpeciesComponentTabsProps): ReactElement {
  const [tabActive, setTabActive] = useState(0)
  const [species, setSpecies] = useState<any[]>([])
  const [state, setState] = useState<State>()
  const [errors, setErrors] = useState<any[]>([])

  const handleActivateSpecie = useCallback((specieId: number) => {
    const index = value.findIndex(specie => specie.specieId === specieId)
    if (index === -1) {
      const specie = species.find(s => s.id === specieId)
      const spe = {
        kpis: specie.kpis.map((kpi: any) => ({ id: kpi.id, value: kpi.value })),
        categoryId: 0,
        share: 0,
        specieId
      }
      value.push(spe)
    } else {
      value.splice(index, 1)
    }
    onChange([...value])
  }, [value, species])

  const checkSpecieActive = useCallback((specieId: number) => {
    const index = value.findIndex(specie => specie.specieId === specieId)
    return index > -1
  }, [value])

  const getSpecieCategory = useCallback((specieId: number) => {
    const index = value.findIndex(specie => specie.specieId === specieId)
    return value[index]?.categoryId
  }, [value])

  const getKpiValue = useCallback((kpiId: number) => {
    const activeSpecie = species[tabActive]
    if (activeSpecie === undefined) return ''
    const spe = value.find(v => v.specieId === activeSpecie.id)
    if (spe === undefined) return '0'
    const kpiIndex = spe.kpis.find((kpi: any) => kpi.id === kpiId)
    if (kpiIndex === undefined) return '0'
    return kpiIndex.value
  }, [value, tabActive, species])

  const handleUpdateCategory = useCallback((categoryId: number, specieId: number) => {
    const index = value.findIndex(specie => specie.specieId === specieId)

    value[index].categoryId = categoryId
    onChange([...value])
  }, [value])

  const handleUpdateKpiValue = useCallback((kpiId: number, kpiValue: string) => {
    const activeSpecie = species[tabActive]
    if (activeSpecie === undefined) return
    const spe = value.findIndex(v => v.specieId === activeSpecie.id)
    const kpiIndex = value[spe].kpis.findIndex((kpi: any) => kpi.id === kpiId)
    if (kpiIndex === -1) return
    value[spe].kpis[kpiIndex].value = kpiValue
    onChange([...value])
  }, [value, species, tabActive])

  useEffect(() => {
    (async () => {
      const response = await api.get('/species')
      setSpecies(response.data.species)
      setState(State.READY)
    })()
  }, [])

  useEffect(() => {
    const errors = value.map(v => {
      const error: any = {
        hasError: false,
        specieId: v.specieId
      }
      if (v.categoryId === 0) {
        error.categoryId = 'Por favor, selecione uma categoria.'
        error.hasError = true
      }
      return error
    })
    onChangeError(errors.findIndex(err => err.hasError) > -1)
    setErrors(errors)
  }, [value, onChangeError])

  const getParent = useCallback((parentId: number) => {
    if (parentId === 0 || parentId === null) return true
    const parent = value.find(specie => specie.specieId === parentId)
    return Boolean(parent)
  }, [value])

  if (state === State.LOADING) return <></>

  return <S.SpeciesComponentTabs>
    <S.SpeciesOptions>
      {species.map((specie, index) => getParent(specie.parentSpecieId)
        ? <S.SpeciesOption
          key={index}
          $isActive={index === tabActive}
          $isSelected={checkSpecieActive(specie.id)}
          onClick={() => setTabActive(index)}
        >
          {specie.name}
        </S.SpeciesOption>
        : <></>
      )}
    </S.SpeciesOptions>
    <S.SpeciesContent>
      {species.map((specie, index) => getParent(specie.parentSpecieId)
        ? <S.SpeciesOptionContent key={index} $isActive={index === tabActive}>
          <div className='switch-container'>
            <p>Ativar</p>
            <S.SpeciesSwitch>
              <input type='checkbox' onChange={() => handleActivateSpecie(specie.id)} checked={checkSpecieActive(specie.id)} />
              <div className='switch-knob'></div>
              <div className='switch-layer'></div>
            </S.SpeciesSwitch>
          </div>
          <div className="inputs-container">
            <CategoryComponent
              disabled={!checkSpecieActive(specie.id)}
              name="category"
              label="Categoria"
              id="category"
              onChange={(categoryId: number) => handleUpdateCategory(categoryId, specie.id)}
              value={getSpecieCategory(specie.id)}
              error={errors.find(err => err.specieId === specie.id)}
            />
            {specie.kpis.map((kpi: any) => <InputText key={kpi.id}
              disabled={!checkSpecieActive(specie.id)}
              name={kpi.name}
              label={kpi.name}
              id={kpi.name}
              onChange={(kpiValue: string) => handleUpdateKpiValue(kpi.id, kpiValue)}
              value={getKpiValue(kpi.id)}
            />)}
          </div>
        </S.SpeciesOptionContent>
        : <></>)}
    </S.SpeciesContent>
  </S.SpeciesComponentTabs>
}

export default SpeciesComponentTabs
