import { FormEvent, ReactElement, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import PageLoaderComponent from '../../../components/PageLoader'
import api from '../../../services/api'
import Template from '../../../template'
import * as S from './styled'

interface LiveProps {
  displayName?: string
  metadata?: string
  enableAt: Date
  disableAt: Date
}

interface AdminNewRoomState {
  isLoading: boolean
  isSaving: boolean
}

function AdminRoomNew (): ReactElement {
  const [state, setState] = useState<AdminNewRoomState>({
    isLoading: true,
    isSaving: false
  })
  const [live, setLive] = useState<LiveProps>({
    enableAt: new Date(),
    disableAt: new Date()
  })

  const handleSave = useCallback(async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    setState((state: AdminNewRoomState) => ({ ...state, isSaving: true }))
    try {
      await api.post('/meeting/room', live)
      toast.success('A sala foi criada com sucesso', { theme: 'colored' })
    } catch (err) {
      toast.error('Ocorreu um erro inesperado. Tente novamente mais tarde', { theme: 'colored' })
    }
    setState((state: AdminNewRoomState) => ({ ...state, isSaving: false }))
  }, [live])

  const dateToInput = useCallback((date: Date) => {
    return date.toLocaleString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).replace(' ', 'T')
  }, [])

  if (!state.isLoading) return <PageLoaderComponent />
  return <Template>
    <div style={{ marginTop: '10rem' }}></div>
    <S.Container>
      <form onSubmit={handleSave}>
        <S.InputText placeholder="Nome da Live" value={live.displayName} onChange={(displayName: any) => setLive((live) => ({ ...live, displayName }))} />
        <S.InputText placeholder="Desscrição" value={live.metadata} onChange={(metadata: any) => setLive((live) => ({ ...live, metadata }))} />
        <S.InputRow>
          <S.InputText placeholder="Habilitar em" type="datetime-local" value={dateToInput(live.enableAt)} onChange={(enableAt: any) => setLive(live => ({ ...live, enableAt: new Date(enableAt) }))} />
          <S.InputText placeholder="Desabilitar em" type="datetime-local" value={dateToInput(live.disableAt)} onChange={(disableAt: any) => setLive(live => ({ ...live, disableAt: new Date(disableAt) }))} />
        </S.InputRow>
        <S.Button disabled={state.isSaving}>{state.isSaving ? 'Salvando...' : 'Salvar'}</S.Button>
      </form>
    </S.Container>
  </Template>
}

export default AdminRoomNew
