import { ReactElement, useCallback, useEffect, useState } from 'react'
import CardContentActionComponent from '../CardContentActionComponent'
import CommentFormComponent from '../CommentFormComponent'
import FeedCommentComponent from '../FeedCommentComponent'
import { FeedContentComponentProps } from './props'
import * as S from './style'

interface CommentProps {
  avatar: string
}

export default function FeedContentComponent ({ content }: FeedContentComponentProps): ReactElement {
  const [comments, setComments] = useState<CommentProps[]>([])
  const onPostComment = useCallback((comment: any) => {
    const newComments = comments
    newComments.push(comment)
    setComments([...newComments])
  }, [])
  useEffect(() => {
    if ('comments' in content) {
      setComments(content.comments)
      return
    }
    setComments([])
  }, [content])
  return <>
    <div dangerouslySetInnerHTML={{ __html: content.message }}></div>
    <S.Container className="post-comment-list">
      {comments.map((comment, index) => <FeedCommentComponent key={index} content={comment} />)}
    </S.Container>
    <CardContentActionComponent />
    <CommentFormComponent onPostComment={onPostComment} />
  </>
}
