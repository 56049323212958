import styled from 'styled-components'

export const CardContainer = styled.div`
  figure {
    height: 11.25rem;
  }

  p, a {
    color: #3E3F5E;
    margin: 0;
    text-decoration: none;
  }
`

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.75rem;
  position: relative;
`

export const PriceContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  display: flex;
  font-weight: 700;
  height: 2rem;
  padding-inline: 0.875rem;
  position: absolute;
  right: 0.875rem;
  top: -0.875rem;

  p {
    font-size: 0.875rem;
    line-height: 2rem;

    span {
      color: #1DF161;
    }
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p, a {
    font-weight: 700;
  }
`

export const CategoryLinkContainer = styled.p`
  padding-left: 0.875rem;
  position: relative;

  a {
    font-size: 0.75rem;
    line-height: 1em;
  }

  &:before {
    border-color: #3ad2fe !important;
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    border: 2px solid #3e3f5e;
    position: absolute;
    top: 0.25rem;
    left: 0;
  }
`

export const DescriptionContainer = styled.p`
  color: #8f91ac !important;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
`

export const CardFooter = styled.div``

export const AuthorContainer = styled.div`
  padding-left: 1.75rem;
  position: relative;

  .avatar {
    left: 0;
    margin: 0 auto;
    max-width: 18px;
    position: absolute;
    top: 4px;
    width: 100%;
  }

  p {
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1em;
  }

  a {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1em;
  }
`
