import { ReactElement } from 'react'
import { ReactionItemComponentProps } from './props'
import * as S from './style'

export default function ReactionItemComponent ({ icon }: ReactionItemComponentProps): ReactElement {
  return <S.Container>
    <img className="reaction-image reaction-item-dropdown-trigger" src={icon} alt="reaction" />

    <div className="simple-dropdown padded reaction-item-dropdown" style={{ position: 'absolute', zIndex: '9999', bottom: '38px', left: '-16px', opacity: '0', visibility: 'hidden', transform: 'translate(0px, 20px)', transition: 'transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s' }}>
      <p className="simple-dropdown-text"><img className="reaction" src={icon} alt="reaction" /> <span className="bold">Funny</span></p>

      <p className="simple-dropdown-text">Matt Parker</p>

      <p className="simple-dropdown-text">Destroy Dex</p>

      <p className="simple-dropdown-text">The Green Goo</p>
    </div>
  </S.Container>
}
