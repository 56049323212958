import { ReactElement, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import logo from '../../assets/img/landing/cargill-branco.png'
import Cargo from '../../components/CargoComponent'
import InputPassword from '../../components/Forms/InputPassword'
import InputText from '../../components/Forms/InputText'
import RegionSelectorComponent from '../../components/RegionSelectorComponent'
import Row from '../../components/Row'
import { UserUpdatePasswordForm } from '../../components/UserUpdatePasswordForm'
import schema from '../../config/selfRegisterFormScheme.json'
import { useAuth } from '../../contexts/AuthContext'
import { useCampaign } from '../../contexts/CampaignContext'
import { useLockedModal } from '../../contexts/LockedModalContext'
import { useModal } from '../../contexts/ModalContext'
import { FormValidator } from '../../domain/FormValidator'
import { api } from '../../services/api'
import { MaskDocument, MaskPhone } from '../../utils/mask'
import RecoveryPassForm from './RecoveryPassForm'
import * as S from './style'
const formValidator = new FormValidator(schema)

interface IUser {
  name?: string
  email?: string
  phone?: string
  cnpj?: string
  region?: {
    id: number
    name: string
  }
  regionId?: number
  holding?: string
  cargo?: string
  document?: string
}

interface IError extends Omit<IUser, 'regionId'> {
  regionId?: string
}

function SuccessModal (): ReactElement {
  const { campaignData } = useCampaign()
  return (
    <>
      <p style={{ fontSize: '1.75rem' }}>
        Bem-vindo a Campanha de incentivo{' '}
        <strong>&quot;{campaignData.title}&quot;</strong>
      </p>
      <p style={{ fontSize: '1.75rem' }}>
        Para acessar a plataforma clique no botão LOGIN e siga os próximo
        passos.
      </p>
      <p style={{ fontSize: '1.75rem', marginTop: '0.75rem' }}>
        <strong>Login</strong>: Email (ponto focal)
      </p>
      <p style={{ fontSize: '1.75rem' }}>
        <strong>Senha</strong>: Email (ponto focal)
      </p>
      <p style={{ fontSize: '1.75rem' }}>
        <h2>
          <center style={{ marginTop: '1.5rem', marginBottom: '0.75rem' }}>
            <strong>Cadastro de Lojas</strong>
          </center>
        </h2>
      </p>
      <p style={{ fontSize: '1.75rem' }}>
        Cadastre todas as lojas que pertencem a sua rede para participar da
        campanha
      </p>
    </>
  )
}

export default function Login (): ReactElement {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<IError>({
    name: '',
    email: '',
    phone: '',
    cnpj: '',
    regionId: '',
    holding: '',
    cargo: '',
    document: ''
  })
  const [loginOrRegister, setLoginOrRegister] = useState('login')
  const navigate = useNavigate()

  const { doLogin, user: loggedUser } = useAuth()
  const { closeModal: closeLocked } = useLockedModal()
  const { closeModal, openModal } = useModal()
  const { campaignData } = useCampaign()

  const [user, setUser] = useState<IUser>({
    name: '',
    email: '',
    phone: '',
    cnpj: '',
    regionId: 1,
    holding: '',
    cargo: '',
    document: ''
  })

  async function handleLogin (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault()
    await doLogin(email, password)

    if (loggedUser?.hasPassword === false) {
      openUpdatePasswordModal()
    }
  }

  const handleConfirmTerm = useCallback(async () => {
    closeLocked()
    navigate('/home')
  }, [closeLocked, navigate])

  const openUpdatePasswordModal = useCallback(() => {
    closeLocked(() => {
      openModal({
        header: 'Atualize sua senha',
        body: <UserUpdatePasswordForm onSave={handleConfirmTerm} />
      })
    })
  }, [closeModal, openModal, handleConfirmTerm])

  async function handleRegister (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault()
    try {
      let hasError = false
      if (!formValidator.validate(user)) {
        setErrors(formValidator.errors)
        hasError = true
      }
      if (hasError) return
      await api.post('register', {
        ...user,
        companyId: String(process.env.REACT_APP_COMPANY_ID)
      })

      setLoginOrRegister('login')

      openModal({
        header: 'Parabéns!',
        body: <SuccessModal />,
        size: 'large'
      })
    } catch (err: any) {
      if (err.response.data.message === true) {
        toast.error(err.response.data.message, {
          theme: 'colored'
        })
      } else {
        switch (err.response.data) {
          case 'Value already exists: user':
            toast.error('Você já está cadastrado', {
              theme: 'colored'
            })
            break
          default:
            toast.error('Ocorreu um erro interno no servidor', {
              theme: 'colored'
            })
            break
        }
      }
    }
  }

  const handleMask = useCallback((login: any) => {
    const charCode = login.charCodeAt()
    if (charCode > 47 && charCode < 58) {
      return MaskDocument(login)
    }
    return login
  }, [])

  const handleAskRecovery = useCallback(() => {
    openModal({
      header: 'Redefinir Senha',
      body: <RecoveryPassForm />
    })
  }, [openModal])

  return (
    <>
      <S.Landing className="landing">
        <div className="landing-decoration"></div>

        <div className="landing-info">
          <img src={logo} className="logo" alt="Logo" style={{ maxWidth: '90px' }} />

          <h1 className="info-title">
            Bem vindo a
            <br />
            <span>{campaignData.title.split('®')[0]}<sup>®</sup></span>
          </h1>
        </div>

        <div className="landing-form">
          {loginOrRegister === 'login'
            ? (
              <S.ContainerLogin className="form-box login-register-form-element login">
                <form className="form mt-0" onSubmit={handleLogin}>
                  <div className="form-row">
                    <div className="form-item">
                      <InputText
                        name="login-username"
                        label="Email ou CPF"
                        onChange={(email: string) => {
                          console.log(email)
                          setEmail(email)
                        }}
                        mask={handleMask}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-item">
                      <InputPassword
                        name="login-password"
                        className="form__input__focus"
                        label="Senha"
                        onChange={(password: string) => {
                          setPassword(password)
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-row space-between">
                    <div className="form-item">
                      <p
                        className="form-link cursor-pointer"
                        onClick={() => handleAskRecovery()}
                      >
                        Recuperar a senha?
                      </p>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-item">
                      <button className="button medium secondary" type="submit">
                        Acesse
                      </button>
{/*
                      <S.Line>
                        <div>Ou</div>
                      </S.Line>

                      <GoogleSocialButton /> */}
                    </div>
                  </div>
                </form>
                <p className="form-text form-link cursor-pointer">
                  Se você tiver problemas, entre em contato pelo e-mail{' '}
                  <a href={`mailto: ${campaignData.email}`}>
                    {campaignData.email}
                  </a>
                </p>
              </S.ContainerLogin>)
            : (
              <S.ContainerRegister className="form-box login-register-form-element register">
                <h2 className="form-box-title pt-0">Crie sua conta!</h2>

                <S.ContainerHolder>
                  <form className="form" onSubmit={handleRegister}>
                    <Row>
                      <InputText
                        name="email"
                        label="Email"
                        value={user.email}
                        onChange={(email: string) => setUser({ ...user, email })}
                        error={errors.email}
                      />
                    </Row>
                    <Row>
                      <InputText
                        name="name"
                        label="Name"
                        value={user.name}
                        onChange={(name: string) => setUser({ ...user, name })}
                        error={errors.name}
                      />
                    </Row>
                    <Row>
                      <Cargo
                        name="cargo"
                        label="Cargo"
                        value={user.cargo}
                        onChange={(cargo: string) => setUser({ ...user, cargo })}
                        error={errors.cargo}
                      />
                    </Row>
                    <Row>
                      <InputText
                        name="phone"
                        label="Celular comercial"
                        value={user.phone}
                        onChange={(phone: string) => setUser({ ...user, phone })}
                        mask={MaskPhone}
                        error={errors.phone}
                      />
                    </Row>
                    <Row>
                      <InputText
                        name="document"
                        label="CPF"
                        value={user.document}
                        onChange={(document: string) =>
                          setUser({ ...user, document })
                        }
                        mask={MaskDocument}
                        error={errors.document}
                      />
                    </Row>
                    <Row>
                      <InputText
                        name="cnpj"
                        label="CNPJ da rede"
                        value={user.cnpj}
                        onChange={(cnpj: string) => setUser({ ...user, cnpj })}
                        mask={MaskDocument}
                        error={errors.cnpj}
                      />
                    </Row>
                    <Row>
                      <InputText
                        name="holding-name"
                        label="Nome da rede"
                        value={user.holding}
                        onChange={(holding: string) =>
                          setUser({ ...user, holding })
                        }
                        error={errors.holding}
                      />
                    </Row>
                    <Row>
                      <RegionSelectorComponent
                        name="region-id"
                        label="Selecione a Regional"
                        value={user.regionId}
                        onChange={(region: { id: number, name: string }) =>
                          setUser({ ...user, regionId: region.id })
                        }
                        error={errors.regionId}
                      />
                    </Row>

                    <div className="form-row">
                      <div className="form-item">
                        <button className="button medium primary" type="submit">
                          Registrar
                        </button>
                      </div>
                    </div>
                  </form>

                  <p className="form-text">
                    Você receberá a confirmação de cadastro por e-mail.
                    <br />
                    Se você tiver problemas, entre em contato pelo e-mail{' '}
                    <a href={`mailto: ${campaignData.email}`}>
                      {campaignData.email}
                    </a>
                  </p>
                </S.ContainerHolder>
              </S.ContainerRegister>)}
        </div>
      </S.Landing>
    </>
  )
}
